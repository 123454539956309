.estimated-delivery-date.loading{
    background: url(ajax-loader.gif) no-repeat 50% 50% #fff;
    min-height:60px
}

.estimated-delivery-date {
    color: black;
    position:relative;
    font-size: 12px;
    text-align:left;

}

.estimated-delivery-date .edd_countdown .knob-container{
    display:inline;
    position:relative;
}
.estimated-delivery-date .edd_countdown .knob-container LABEL{
    font-weight: bold;
    bottom: -20px;
    left: 0;
    margin: auto;
    display: block;
    width: 100%;
    position: absolute;
    text-align: center;
}

.estimated-delivery-date .edd_countdown.knob-countdown{
    display:block;
    height:auto;
    margin-top:20px;
    margin-bottom: 20px;
}
.estimated-delivery-date {
    
    display: flex;
    align-items: center;
    gap: 16px;
    &:before {
        content: url('data:image/svg+xml,<svg width="51" height="38" viewBox="0 0 51 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M35.2323 28.2279H3.42773L8.24661 1.24219H40.0512L35.2323 28.2279Z" stroke="%23327C7D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M46.7977 28.2285H35.2324L38.1237 11.8443H43.4245C44.3803 11.8391 45.3237 12.0606 46.1772 12.4907C47.0308 12.9209 47.7701 13.5474 48.3345 14.3187C48.8989 15.0901 49.2723 15.9843 49.424 16.928C49.5757 17.8717 49.5014 18.8379 49.2072 19.7473L46.7977 28.2285Z" stroke="%23327C7D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.4956 36.7582C13.7783 36.7582 14.8183 35.7183 14.8183 34.4355C14.8183 33.1527 13.7783 32.1128 12.4956 32.1128C11.2128 32.1128 10.1729 33.1527 10.1729 34.4355C10.1729 35.7183 11.2128 36.7582 12.4956 36.7582Z" stroke="%23327C7D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M32.437 36.7582C33.7198 36.7582 34.7597 35.7183 34.7597 34.4355C34.7597 33.1527 33.7198 32.1128 32.437 32.1128C31.1542 32.1128 30.1143 33.1527 30.1143 34.4355C30.1143 35.7183 31.1542 36.7582 32.437 36.7582Z" stroke="%23327C7D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M1.5 11.8442H15.9566" stroke="%23327C7D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        width: 48px;
    }

    color: #327C7D;
    position: relative;
    font-size: 12px;
    text-align: left;
    padding: 16px;
    background: #F3F7F7;
    border-radius: 8px;
    margin-bottom: 16px;
    p {
        color: #327C7D;
    }
}