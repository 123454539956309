/* .turbolinks-progress-bar {
	// height: 5px;
	// background-color: $color-brand;
} */


:root {
	--max-width-container: 1200px;
}
// TEST WITHOUT ALERT
body:not(.cms-index-index) {
	
	.main-container {
		max-width: var(--max-width-container);
	}

	.main-container {
		margin: 0 20px;
		@media (min-width: 1220px) {
			margin: 0 auto;
		}
		
		@media (min-width: 1220px) {
	    	//margin: 0;
	  	}

		.main {
			margin-block: 8px;
		}
	}
	.page:has(.breadcrumbs) {
		.main {
			margin: 0;
		}
	}

	/* .page {
		// background: #fafafa;
	} */
}

/*! purgecss start ignore */
.customer-account-forgotpassword,
//.customer-account-create,
//.contacts-index-index,
.sales-guest-form,
.catalogsearch-advanced-index {
	.main-container {
		max-width: fit-content !important;
	}
	.main {
		margin-top: 32px;
	}
	
}
/* .contacts-index-index {
	.col2-set {
		gap: 3%;
	}
} */
/*! purgecss end ignore */

.demo-notice {
	display: none;
}

.wrapper {
	min-width: 360px;
	margin: 0 auto;
}



.main-container,
.footer-container {
	margin: 0 auto;
}

.page {
	// background: #f7f7f7;
	.page-header {
		/* background: #fafdfd; */
	}
}
/*! purgecss start ignore */
.category-preheader-wrapper {
	background: #F5F3EC;
	padding: 20px 0;
	margin-bottom: 20px;
	@media (min-width: 1200px) {
		margin-bottom: 40px;
	}
	
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	/* & > *:first-of-type {
		max-width: var(--max-width-container);
		margin: 0 auto !important;
	} */
	.category-title {
		max-width: var(--max-width-container);
		margin: 0 20px !important;
		@media (min-width: 1200px) {
			margin: 0 !important;
		}
		h1 {
			text-align: center;
		}
	}
	.wrap-subcat {
		max-width: calc(100vw - 32px);
		max-width: calc(100dvw - 32px);
		@media (min-width: 1200px) {
			max-width: auto;
		}
	}
}
.breadcrumbs {
	grid-area: header; 
	padding: 10px 0;
	/* @media (min-width: 1024px) {
		padding: 6px 0;
	} */
	/* border-bottom: 1px solid #ccc; */
	ul {
		display:flex;
		flex-wrap: wrap;
		flex-direction: column;
		@media (min-width: 1024px) {
			flex-direction: row;
		}
		--bread-fs: 12px;
		font-size: var(--bread-fs);
		& > li {
			
			/* font-size: 13px;
			line-height: 10px; */
			font-size: var(--bread-fs);
			line-height: inherit;
			//font-variant: all-small-caps;
			//text-transform: uppercase;
			display: none;
			align-self: start;
			align-items: center;
			&:nth-last-child(2) {
				display: flex;
				flex-direction: row-reverse;
				svg {
					transform: rotate(180deg);
					margin-right: 8px;
				}
			}
			&.product {
				display: none;
				/* margin-top: 8px;
				font-size: 16px;
				line-height: inherit; */
			}
			@media (min-width: 1024px) {
				display: flex;
				align-self: center;
				&:nth-last-child(2) {
					flex-direction: row;
					svg {
						transform: none;
					}
				}
				&.product {
					display: flex;
					margin-top: 0;
					font-size: inherit;
					line-height: inherit;
				}
				
				/* font-size: 14px;
				line-height: 12px; */
				strong {
					color: $color-muted;
					font-weight: normal;
				}
				svg {
					margin: 0 4px;
				}
			}
			

			svg {
				display: inline-block;
				height: 12px;
				width: 12px;
				// @media (min-width: 1024px) {
				// 	height: 12px;
				// 	width: 12px;
				// }
				stroke: $color-brand;
			}
		}
	}
}
.col2-left-layout > .main {
	display: flex;
	flex-direction: column;
	.breadcrumbs {
		order: 1;
	}
	.col-main {
		order: 2;
	}
	.col-left {
		order: 3;
	}
	@media (min-width: 1024px) {
		display: grid;
		grid-template-areas:
			'header header header header' 
			'left   main   main	  main';
		grid-template-columns: 1fr 3fr;
		gap: 0 max(48px,2%);
		.col-left {
			grid-area: left; 

			position: sticky;
			top: 20px;
			align-self: start;
			height: fit-content;
			min-width: 285px;

		}
		.col-main {
			grid-area: main; 
		}
		.col-left:last-child {
			display: none;
			grid-area: right; 
		}
	}
}
.col3-layout > .main {
	.col-wrapper {
		display: contents;
	}
	@media (min-width: 1024px) {
		display: grid;
		grid-template-areas:
			'header header header' 
			'left   main   right ';
		grid-template-columns: 1fr 4fr 1fr;
		gap: 0 32px;

		.col-left {
			grid-area: left; 
	
			position: sticky;
			top: 150px;
			align-self: start;
			height: fit-content;
		}
		.col-main {
			grid-area: main; 
		}
		.col-right {
			grid-area: right; 
		}
		.col-left:last-child {
			display: none;
			grid-area: right; 
		}
	}
}

[aria-busy] {
	--loading-size: 18px;
	--loading-stroke: 6px;
	--loading-duration: 1s;
	position: relative;
	-webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
	-o-transition: all .25s cubic-bezier(.645,.045,.355,1);
	transition: all .25s cubic-bezier(.645,.045,.355,1);	
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 99;
		-webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
		-o-transition: all .25s cubic-bezier(.645,.045,.355,1);
		transition: all .25s cubic-bezier(.645,.045,.355,1);
		background: radial-gradient(rgba(255,255,255,.4), transparent);
		backdrop-filter: blur(1px);
	}
	&:after {
		content: '';
		position: absolute;
		z-index: 999;
		top: 50%;
		left: 50%;
		width: var(--loading-size);
		height: var(--loading-size);
		margin-top: calc(var(--loading-size) / 2 * -1);
		margin-left: calc(var(--loading-size) / 2 * -1);
		border: var(--loading-stroke) solid rgba(0, 0, 0, 0.15);
		border-radius: 50%;
		border-top-color: $color-brand;
		animation: rotate calc(var(--loading-duration)) linear infinite;
	}
}
@keyframes rotate {
	100% { transform: rotate(360deg); }
}

/* .htmx-request,
.htmx-swapping {
	pointer-events: none;
	position: relative;
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		
		display: grid;
		justify-content: center;
		align-content: center;
	}
	&:before {
		z-index: 99;
		content: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" id="layered_nav__LoadingIndicator"><defs><linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a"><stop stop-color="%23fff" stop-opacity="0" offset="0%"/><stop stop-color="%23fff" stop-opacity=".631" offset="63.146%"/><stop stop-color="%23fff" offset="100%"/></linearGradient></defs><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)"><path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(%23a)" stroke-width="2"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></path><circle fill="%23fff" cx="36" cy="18" r="1"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite"/></circle></g></g></svg> ');
		-webkit-transition: all .25s cubic-bezier(.645,.045,.355,1);
		-o-transition: all .25s cubic-bezier(.645,.045,.355,1);
		transition: all .25s cubic-bezier(.645,.045,.355,1);
		background: radial-gradient(rgba(255,255,255,.2), transparent);
		backdrop-filter: blur(1px);
	}
}
.htmx-swapping {
	opacity: 0;
	transition: opacity 1s ease-out;
}
.htmx-added {
	opacity: 0;
	transition: opacity 1s ease-out;
} */

body[class*="cms-faq-"] {
	.page-title {
		margin-bottom: 0;

	}
}

.cms-page-view .std {
	.faq {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 8px;
	
		& > a {
			text-decoration: none !important;
			--padding-inline: 20px;
			--padding-block: 16px;
			--flex-basis: 50%;
			max-width: 120px;
			@media (min-width: 768px) {
				--flex-basis: 25%;
				max-width: 190px;
			}
			flex: 1 1 calc((var(--flex-basis)) - calc(var(--padding-inline) * 2 ) - 4px);
			display: flex;
			align-items: center;
			flex-direction: column;
			gap: 16px;
	
			background: #fff;
			padding: var(--padding-block) var(--padding-inline);
			border-radius: 8px;

			svg {
				flex-grow: 1;
				max-height: 60px;
			}
	
			& > span {
				flex-grow: 1;
				display: flex;
				align-items: center;
				max-width: 160px;
				color: #000;
				font-size: 14px;
				line-height: 23px;
				font-weight: 700;
			}
			&:hover span {
				color: $color-brand;
				text-decoration: underline;
			}
		}
	
	}
}
@keyframes sweep {
0% {
	opacity:0
}
to {
	opacity:1
}
}
details {
	summary {
		cursor: pointer;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		outline: 0;
		padding: 20px 8px;
		position: relative;
		text-align: left;
		border-bottom: 1px solid #e0e0e0;
		&::after {
			content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="11" viewBox="0 0 18 11" fill="none"><path d="M1 1L9 9L17 1" stroke="black" stroke-width="2" stroke-linecap="round"/></svg>');
			position: absolute;
			right: 20px;
		}
	}
	&[open] {
		summary::after {
			transform: rotate(180deg);
		}
		summary~* {
			animation: sweep .3s ease-in-out;
		}
	}
	& > div {
		padding: 20px 8px;
	}
}



/*
.cp-align-center { text-align: center; }
.cp-round-1 { border-radius: 8px; }
.cp-round-2 { border-radius: 6px; }

.cp-flex1 {
	border-radius: 8px;
	@media (min-width: 1024px) {
		display: flex; 
		flex-direction: row;
		align-items: center;
		padding: 20px; gap: 20px;
	}
}

.cp-flexa {	flex: auto; }

.cp-p1 { 
	padding: 20px; 
	position: relative;
	@media (min-width: 1024px) {
		padding: 0;
	}
}

iframe[src*="youtube"] { border-radius: 6px; }

.cp-s1 {
	position: relative;
	padding: 10px;
	max-width: 500px;
	margin: 0 auto;
	@media (min-width: 1024px) {
		width: 380px;
		padding: 0;;
	}
}

.cp-grid {
	& img { border-radius: 10px; }
	@media (min-width: 1024px) {
		display: grid;
		grid-gap: 60px;
		grid-template-columns: repeat(2, 1fr);
	}
}

.cp-grid > div { 
	position: relative; 
	margin: 0 0 20px 0;
	@media (min-width: 1024px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
	}
}

.cp-grid.cp-grid-as > div { 
	@media (min-width: 1024px) {
		justify-content: start;
	}
}

.cp-grid img { 
	display: block; 
	margin: 15px auto; 
	@media (min-width: 1024px) {
		margin: 0 auto; 
	}
}

.cp-grid.cp-grid-v1 {
	@media (min-width: 1024px) {
		grid-template-columns: auto 60%;
	}
}

.cp-grid.cp-grid-v2 {
	@media (min-width: 1024px) {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 4%;
	}
}



.cp-grid-3 {
	& > div { margin: 0 0 40px 0; text-align: center; position: relative; }
	& img { margin-left: auto; margin-right: auto; }
	@media (min-width: 1024px) {
		display: grid;
		grid-gap: 60px;
		grid-template-columns: repeat(3, 1fr);
		& > div { margin: 0; }
	}
}

.cp-bg-1 { padding: 20px 10px; background-color: #F5F3EC; border-radius: 8px; }
.cp-bg-1-2 { padding: 20px 25px; }

.cp-h-img { 
	display: flex; 
	flex-direction: row; 
	align-items: center; 
	gap: 10px; 
	justify-content: center; 
	margin: 0 !important;
	& img { 
		margin: 0;  
		width: 40px;
		height: auto;
	}
}


.cm-moa { margin-left: auto; margin-right: auto; }
*/


.cms-page-view .std ul.cp-ul {
	list-style-type: none;
	margin: 0 0 15px 0;
	padding: 0;
	& li {
		display: block; 
		padding: 0 0 0 35px;
		min-height: 35px;
		background-image: url("/media/wysiwyg/cms/point.png") !important;
		background-repeat: no-repeat;
		background-position: 1px 1px;
		line-height: 1.3;
		margin: 15px 0;
	}
}

.cms-page-view .std ul.cp-ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
	& li {
		display: block;
		min-height: 26px;
		margin: 15px 0;
		padding: 0 0 0 20px;
		position: relative;
	}
	& li span {
		display: block; 
		position: absolute;
		top: 2px;
		left: 0;
		line-height: 1;
		font-size: 24px;
		color: $color-brand;
	}
}



/*! purgecss end ignore */