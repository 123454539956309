/*! purgecss start ignore */
/*.clearfix:after,*/
.configurable-swatch-list-wrap {
  text-align: center;
}
.configurable-swatch-list:after,
.product-view .product-options .swatch-attr:after {
  content: '';
  display: table;
  clear: both;
}

/* General Swatch Styling */
.swatch-link,
.swatch-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
  color: #636363;
  text-decoration: none;
  box-sizing: content-box;
  overflow: hidden;
}

.product-options .configurable-swatch-list .swatch-link:not(.has-image) {
  outline: 1px solid #928f8f;
  padding: 8px 12px;
  text-decoration: none !important;
  background: #fff;
}
.product-options .configurable-swatch-list .swatch-link.has-image {
  outline: 1px solid #928f8f;
  padding: 2px;
  background: white;
}
.swatch-link img,
.swatch-link.has-image,
.swatch-link.has-image .swatch-label {
  /* border-radius: 50%; */
}
.swatch-link:hover {
  cursor: pointer;
  text-decoration: none;
}
.swatch-link .x {
  display: none;
  text-indent: -999em;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../images/bg_x.png) center no-repeat transparent;
  z-index: 10;
}
.swatch-link.has-image .swatch-label {
  position: relative;
}
.swatch-link.has-image img {
  position: absolute;
  top: 0;
  left: 0;
}

.swatch-label {
  border: 1px solid #fff;
  margin: 0;
  white-space: nowrap;
}
.swatch-link:not(.has-image) .swatch-label {
  // padding: 0 9px;
}
.configurable-swatch-list {
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  @media (min-width: 778px) {
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  }
  width: 100%;
  gap: 10px 8px;
}
.product-options .configurable-swatch-list li {
  zoom: 1;
  width: auto;
  flex: 0 0 110px;
}
.product-options .configurable-swatch-list {
  flex-wrap: wrap;
}
.products-grid .configurable-swatch-list li {
  display: inline-block;
  float: none;
  margin: 0;
  vertical-align: top;
}
.configurable-swatch-list .not-available .x {
  display: block;
}
.configurable-swatch-list .not-available .swatch-link {
  position: relative;
  background: #fff !important;
  border-color: #ededed !important;
}
.configurable-swatch-list .not-available .swatch-link.has-image img {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.configurable-swatch-list .not-available .swatch-label {
  color: #aaa !important;

}
.configurable-swatch-list .wide-swatch .swatch-label {
  padding: 0 6px;
}
.configurable-swatch-list .not-available a:focus {
  //outline: 0;
}

#narrow-by-list dd .configurable-swatch-list {
  grid-template-columns: repeat(auto-fill,minmax(60px,1fr));
  li {
    /* margin: 0 0 0 3px; */
    padding: 0;
    background: none;
  } 
}
#narrow-by-list dd .swatch-link {
  border: none;
  line-height: 25px;
  /* margin-right: 2px; */
  text-align: left;
}
#narrow-by-list dd .swatch-link.has-image {
  line-height: inherit;
  .swatch-label {
    border-radius: 50%;
    img {
      border-radius: 50%;
    }
  }
  
}
#narrow-by-list dd .swatch-link:hover {
  text-decoration: none;
  background: none;
}
#narrow-by-list dd .swatch-link:hover .swatch-label {
  border-color: $color-brand;
  color: $color-brand;
}
#narrow-by-list dd .swatch-label {
  flex-basis: 100%;
  border: 1px solid #928f8f;
  line-height: 1.5em;
  padding: 1px 5px;
  white-space: nowrap;
}
#narrow-by-list dd .swatch-label img {
  border: 1px solid #fff;
  border-radius: 5px;
  box-sizing: content-box;
}
#narrow-by-list dd .has-image .swatch-label {
  padding: 0;
}

@media only screen and (max-width: 770px) {
  #narrow-by-list dd .configurable-swatch-list li:nth-child(odd) {
    clear: left;
  }
}
.currently .swatch-current {
  position: relative;
  display: flex;
  gap: 6px;
  .value {
    display: flex;
    gap: 4px;
  }
}
/* .currently .swatch-current span {
  display: block;
  float: left;
}
.currently .swatch-link {
  display: inline-block;
  margin: 0 0 0 3px;
} */
.currently .swatch-link:hover {
  border-color: #928f8f;
  cursor: default;
}

/* Other Swatch States */
.configurable-swatch-list .hover:not(.not-available) .swatch-link,
.configurable-swatch-list .selected:not(.not-available) .swatch-link,
.configurable-swatch-list :not(.not-available) .swatch-link:hover {
  outline-color: $color-brand;
  outline-width: 2px;
  //background: $color-brand;

}
.configurable-swatch-list .hover .swatch-link span,
.configurable-swatch-list .selected .swatch-link span,
.swatch-link:hover span {  
  color: $color-brand;
}

.product-options .configurable-swatch-list :not(.not-available) .swatch-link:hover > span {
  color: $color-brand;
}

.configurable-swatch-list .selected .swatch-link {
  position: relative;
  span {
    color: $color-brand;
    font-weight: bold;
  }
  &::before {
    display: block;
    content: "";
    bottom: 4px;
    right: 6px;
    position: absolute;
    border-style: solid;
    border-width: 0 2px 2px 0;
    border-color: white;
    height: 6px;
    width: 3px;
    aspect-ratio: 1/1;
    margin: 0;
    transform: rotate(45deg);
    z-index: 1;
  }
  &::after {
    display: block;
    content: "";
    bottom: 0;
    right: 0;
    position: absolute;
    background: $color-brand;
    height: 15px;
    width: 18px;
  }
}
.configurable-swatch-list .not-available.selected .swatch-link {
  &::before {
    content: "x";
    position: absolute;
    margin: 0;
    border: none;
    transform: none;
    height: 18px;
    width: 20px;
    color: #fff;
    bottom: 2px;
    right: 0;
  }
  &::after {
    background: $color-danger;
  }
}

.configurable-swatch-box {
  background: none !important;
}
.configurable-swatch-box select.swatch-select {
  display: none;
}
.configurable-swatch-box .validation-advice {
  margin: 0 0 5px;
  background: #df280a;
  padding: 2px 5px !important;
  font-weight: bold;
  color: #fff !important;
  float: left;
  display: block;
  border-radius: 5px;
}

.product-view .product-options .swatch-attr .select-label {
  display: inline-block;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  /* color: $color-muted;
  padding-left: 5px; */
}
/*! purgecss end ignore */