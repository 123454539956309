/* @mixin transform-duration-5s {
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    -o-transition: transform .5s;
    transition: transform .5s;
    transition: transform .5s,-webkit-transform .5s;
}
@mixin transistion-duration-3s {
    transition: .3s;
	-moz-transition: .3s;
	-webkit-transition: .3s;
	-o-transition: .3s;
}
@mixin transistion-duration-2s {
    transition: .2s;
	-moz-transition: .2s;
	-webkit-transition: .2s;
	-o-transition: .2s;
}
 */
 @mixin title-decoration($color: $color-brand) {
    @content;
    position: relative;
    padding-bottom: 20px;
    &:after {
      content: "";
      width: 60px;
      height: 6px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $color;
      border-radius: 8px;
    }
}

@mixin right-blur($color: #fff, $width: 20%) {
    position: relative;
    @content;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: $width;
        height: 100%;
        background: linear-gradient(to right, rgba(1,1,1, 0), $color);
        pointer-events: none;
    }
}

@mixin scrollbar-mini($color-track: white, $color-thumb: #eee) {
    /* overflow-x: hidden;
    &:focus,
    &:focus-visible,
    &:hover {
        overflow-x: auto;
    } */

    @media (max-width: 777px) {    
        scrollbar-color: transparent transparent;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 0;
            width: 0;
            background: transparent;
            border-radius: $border-radius-2;
        }
    }
    @media (min-width: 778px) {
        scrollbar-width: thin;
        scrollbar-color: $color-thumb $color-track;
        
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: $color-track;
        }
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-track {
            border-radius: $border-radius-2;
        }

        &::-webkit-scrollbar-track {
            background: $color-track;
        }

        &::-webkit-scrollbar-thumb {
            background: $color-thumb;
        }

        &::-webkit-scrollbar-thumb:hover {
            background:  $color-track;
        }
    }
}