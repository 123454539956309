.product-image .product-badges { 
	top: 16px;
	@media (min-width: 768px) {	
		top: 26px;
		right: 0;
		bottom: auto;
		left: auto;
		align-items: end;
		flex-direction: column;
	}
}
.product-badges {
	position: absolute;
	top: 16px;
	@media (min-width: 768px) {	
		top: 26px;
	}
	left: -2px;

	display: flex;
	/* flex-direction: column; */
	flex-wrap: wrap;
	gap: 4px;
	@media (min-width: 768px) {
		gap: 8px;
	}

	& > * {
		font-size: 12px;
		padding: 4px 20px 4px 4px;
		@media (min-width: 768px) {
			padding: 6px 28px 6px 8px;			
			font-size: 14px;
		}
		clip-path: polygon(0 0, 90% 0, 80% 50%, 90% 100%, 0 100%);
		/* border-radius: 8px 0 8px 0; */
		/* font-weight: bold; */
		/* border-width: 1px;
		border-style: solid; */
		/* border-radius: 0 4px 4px 0; */
		/* border-radius: 4px; */
		font-weight: bold;
		/* box-shadow: 2px 2px 2px rgba(0, 0, 0, .2); */

		line-height: 1;
		width: max-content;
	}
	.sale {
		/* background: #ffef98;
		color: #ff0048; */
		/* background: linear-gradient(90deg, #3baa35 0%, #48c841 100%);
		color: white; */
		//background: linear-gradient(90deg, $color-sale 0, #e62e00 100%);
		/* background: $color-sale;
		color: #fff; */

		color: #fff;
		background: $color-sale;
		/* border-color: $color-sale;
		border-right-color: #e8c0b6; */
	}
	.out-of-stock {
		background: #fff;
		color: $color-hot;
	}
	.new {
		/* background: linear-gradient(90deg, #3baa35 0%, #48c841 100%); */
		background: $color-new;
		color: white;
		/* background: #caebff;
		color: #066197; */
	}

	
	.backorder,
	.info {
		color: white;
	}
	.backorder {
		/* background: #054b75; */
		background: white;
		color: $color-body;
	}
	.info {
		background: $color-best;
	}
}
.block-product-slider {
	.product-badges {
		& > * {
			padding: 4px 16px 4px 4px;
			font-size: 12px;
		}
	}
}
