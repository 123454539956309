.skip-nav {
    // width: 40%;
    @media (hover: hover) and (pointer: fine) {
        &:not(.skip-active):hover {
            text-decoration: none;

            .icon {
                
            }
        }
    }
}

.skip-nav .label {
    display: inline;
    color: $color-brand;
}

.skip-nav .icon {
    
}

.block-nav .block-title > strong span {
    font-size: 20px;
}
.block-nav .nav-primary {
    & > li {
/*         border: 1px solid #88969f;
        padding: 8px 16px;
        border-radius: 8px;
        background: linear-gradient(-180deg, #fff 0, #e6e4e4 100%); */
        /* & > a {
            color: $color-brand;
        } */
    }
}

/* ============================================ *
 * Nav Primary
 * ============================================ */

 // offerte
/*! purgecss start ignore */
.nav-12 > a {
    color: #e53e59;
    font-weight: 600 !important;
}
/*! purgecss end ignore */

.wrap-nav {
    @media (min-width: 1024px) {
        nav { width: 100%; }
        @include right-blur($color: #fff);
        &:after {
            display: none;
        }
    }
    ol {
        @media (min-width: 1024px) {
            margin-left: 0;
        }
    }
    .nav-primary {    
        /* --activemenu: #30254e;
        --bordermenu: #30254e;
        --textmenu: #fff; */
        --activemenu: #D92A64;
        --bordermenu: #dadada;
        --textmenu: #fff;
        
        /* padding: 4px 0; */
        @media (min-width: 1024px) {
            display: flex;
            width: 100%;
            gap: 0;
            justify-content: space-between; 
            //flex-wrap: wrap;
            
        }
        
        @media (min-width: 1024px) {
            &:not(:hover) {
                & > li.active {
                    color: var(--activemenu);
                    border-radius: 4px;
                    /* & > a {
                        color: var(--textmenu);
                    } */
                }
            }
        }
        & > li {
            &.active > a {
                color: var(--activemenu);
            }
            @media (min-width: 1024px) {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                //padding: 0 24px;
                /* flex: 1 0 auto;
                //max-width: 126px; */
                &:first-child {
                    //padding-left: 16px;
                }
                &:last-child {
                    padding-right: 0;
                }

                /* &:not(:last-child):after {
                    content: '◦';
                } */
                
                & > a {
                    padding: 5px 0;
                    text-align: center;
                    &:after {
                        display: none !important;
                    }
                }
                // 2nd level
                &:hover {
                    border-left-color: transparent !important;
                    & + li {
                        border-left-color: transparent !important;
                    }
                    position: relative;
                    color: var(--activemenu);
                    border-radius: 4px;
                    &:has(a.has-children) {
                        border-radius: 4px 4px 0 0;
                    }
                    & > a {
                        color: var(--activemenu);
                    }
                    > ul {
                        border-color: var(--bordermenu);
                        border-width: 2px;
                        border-style: solid;
                        border-radius: 0 4px 4px 4px;
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        position: absolute;
                        top: 32px;
                        /* @media (min-width: 1400px) {
                            top: 32px;
                        } */
                        left: -1px;
                        right: 0;
                        overflow: visible;
                        z-index: 999;
                        background: #fff;
                        padding: 8px 16px;
                        width: max-content;
                        box-shadow: rgba(124,58,237,.08) 0 3px 4px 0;
                        li {
                            &.view-all {
                                //display: none;
                                a {
                                    font-weight: 600;
                                }
                            }
                            a {
                                display: flex;
                                justify-content: space-between;
                                text-align: left;
                                font-size: 16px;
                                font-weight: normal;
                                text-transform: none;
                                padding: 6px 0;
                                width: 100%;
                            }
                            ul {
                                display: none;
                            }
                            &.parent:has(a:hover),
                            &.parent:hover,
                            &.parent:hover > ul li:hover {
                                //margin-left: -8px;
                            }
                            &:hover > a {
                                color: var(--activemenu);
                            }
                            // 3rd level
                            &:hover > ul {
                                border-width: 2px;
                                border-style: solid;
                                border-radius: 6px;
                                display: flex;
                                flex-direction: column;
                                gap: 4px;
                                position: absolute;
                                top:  calc(100% - 24px);
                                left: calc(100% - 32px);
                                overflow: visible;
                                z-index: 999;
                                padding: 8px 16px;
                                width: max-content;
                                box-shadow: rgba(0, 0, 0, 0.18) 0 3px 4px 0;
                                
                                background-color: #fff;
                                border-color: var(--bordermenu);
                                /*transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
                                &:hover {
                                    background-color: $color-body;
                                    border-color: $color-body;
                                } */
                                li {
                                    /* list-style: inherit;
                                    margin-left: 16px; */
                                    &.view-all {
                                        display: none;
                                        list-style: none;
                                        margin-left: 0;
                                        a {
                                            font-weight: 600;
                                        }
                                    }
                                    a {
                                        display: flex;
                                        justify-content: space-between;
                                        text-align: left;
                                        font-size: 16px;
                                        font-weight: normal;
                                        text-transform: none;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        li:not(.view-all) + li {
                            border-top: 1px solid #dcdcdc;
                            padding-top: 6px;
                        }
                    }
                }
            }
        }
    }
}

.nav-primary {
    
    // justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-x: unset;
    li + li {
        border-top: 1px solid gainsboro;
        padding-top: 8px;
        @media (min-width: 1024px) { 
            border: none;
            padding: 0;
        }
    }
    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;;
    }
    

    .skip-nav {
        flex-direction: row;
        gap: 8px;
        svg {
            width: 24px;
        }
    }
    .offers > a {
        color: #0051ffb4;
    }
}
.nav-primary {
    .nav-0 {
        display: none;
        @media (min-width: 1024px) {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            max-width: calc( 220px - 32px);
            &.level0 > a,
            &.level0 > button, {
                font-size: 18px;
            }
        }
        &.level0 > a {
            // font-weight: 900;
            // color: $color-brand;
        }
    }
}

/* ============================================ *
 * General Navigation Styles
 * ============================================ */

.nav-primary {
    a {
        text-decoration: none;
        //text-transform: uppercase;
        position: relative;
        display: block;
    }

    li {
        position: relative;
    }

    & > li:after {
        // display: block;
        // content: '';
        // background: #ccc;
        // height: 1px;
        // margin-top: 8px;
    }

    li.level1 a {
        
    }

    li.view-all {
        padding-top: 12px;
        @media (min-width: 1024px) {
            order: 1;
        }
        border-top: 1px solid #ccc;
        a {
            font-weight: bold;
            //font-size: 14px;
            /* @media (min-width: 1024px) {*/
                
            /*} */
            color: $color-brand;
        }
    }
}

// Show sub-menus when JS adds the appropriate classes
.nav-primary .menu-active > ul.level0,
.nav-primary li.level0 li.sub-menu-active > ul {
    display: block;
    // border-bottom: 2px solid #ccc;
}


.nav-primary li > a,
.nav-primary li > button {
    font-weight: 400;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    /* text-align: center; */
    padding: 4px 0px;
    
    
    // text-transform: uppercase;
    //font-size: 16px;
    //line-height: normal;
    font-size: 16px;
    // border-bottom: 2px solid #ccc;
    @media (min-width: 1024px) {
        font-size: 12px;
        line-height: 12px; 
        
        // border: none;
        // font-size: 14px;
        // font-weight: 900;
        // color: #fff;
        &:hover {
            color: $color-brand;
            // text-decoration: underline;
        }
    }
    
    @media (min-width: 1400px) {
        font-size: 16px;
        line-height: 18px; 
    }
    &.has-children {
        //font-weight: 600;
        &:after {
            // margin-top: 4px;
            margin-left: 4px;
            margin-right: 8px;
            display: inline-block;
            
            //background-image: url(../imgs/theme/icons/arrowdown2.svg);

            
            content: url('data:image/svg+xml,\
                <svg viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">\
                    <path d="M1.97275 3.71249L4.68942 6.42916C5.01025 6.74999 5.53525 6.74999 5.85609 6.42916L8.57275 3.71249" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\
            </svg>');
            transform: rotate(-90deg);
            @media (min-width: 1024px) {
                //transform: rotate(0);
                //display: none;
            }
            width: 18px;
        }
    }
}
.nav-primary li.level0 > a {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    @media (min-width: 1024px) {
        text-transform: none;
        font-size: 18px;
    }
    line-height: normal;
}

// .nav-primary li.level0.menu-active > a {
//     // border: none;    
//     color: $color-brand;
//     @media (min-width: 1024px) {
//         color: #fff;
//         text-decoration: underline;
//     }
// }

/* .nav-primary ul.level0 {
    a {
        transition: color .15s ease-in-out;
        position: relative;

        &:after {
            border-bottom-color: transparent;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            bottom: -3px;
            content: "";
            display: block;
            height: 0;
            left: 0;
            position: absolute;
            transition: border .15s ease-in-out;
            width: 100%;
        }

        &:hover {
            text-decoration: none;

            &:after {
                border-bottom-color: inherit !important;
            }
        }
    }

    & > li {
        margin-bottom: 4px;

        &.active a:after {
            border-bottom-color: inherit !important;
        }
    }
} */



.nav-primary li.menu-active {
    /* list-style-type: disclosure-closed; */
    border-color: $color-brand;
    & > a {
        color: $color-brand;
    } 
    > ul {
        display: block;
        margin: 8px auto 8px auto;
    }
}


// slide menù
.menu-wrapper {
    position: relative;
    touch-action: manipulation;
    /* overflow: hidden; */
}

.list-wrapper {
    height: 100%;
    /* overflow-y: auto; */
    background-color: white;
    
    
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (min-width: 1024px) {
        justify-content: space-between;
        flex-direction: row;
        background-color: transparent;
    }
    position: relative;
    z-index: 90;
}

.list-wrapper:nth-child(2),
.list-wrapper:nth-child(3) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateX(100%);
    backface-visibility: hidden;
    transition: transform 0.5s, opacity 0.6s;
    opacity: 0;
    .sub-menu-wrapper ul {
        background: #fff;
        height: max(100vh, 100%);
        margin-bottom: 16px;
        ul li.view-all {
            display: none;
        }
    }
    @media (min-width: 1024px) {
        display: none;
    }
}

.list-wrapper:nth-child(2).is-visible,
.list-wrapper:nth-child(3).is-visible {
    transform: none;
    opacity: 1;
}

.list-wrapper:nth-child(1)>nav>ol>li>ul,
.list-wrapper:nth-child(2) .level-3 {
    display: none;
}

.menu-wrapper a {
    display: inline-block;
    position: relative;
}

.menu-wrapper a.has-children {
    display: flex;
    align-items: center;
    place-content: space-between;
}

@media (min-width: 1024px) { 
    .menu-wrapper a.has-children {
        gap: 0;
    }
    .nav-primary li + li {

        //border-left: 1px solid gainsboro;
    }
}

.menu-wrapper a.has-children:after {
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='current'><path d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z'></path></svg>");
    transform: rotate(180deg);
}

.menu-wrapper li:not(.view-all) a:not(.has-children) {
/*     text-decoration: underline;
    @media (min-width: 1024px) { 
        text-decoration: none;
    } */
}

.nav-back {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid gainsboro;
    place-content: space-between;
}

.back-one-level {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    gap: 8px;
}

.back-one-level::before {
    content: url('data:image/svg+xml,<svg viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.97275 3.71249L4.68942 6.42916C5.01025 6.74999 5.53525 6.74999 5.85609 6.42916L8.57275 3.71249" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    transform: rotate(90deg);
    width: 12px;
    aspect-ratio: 1 / 1;
}

.back-one-level > span {
    /* flex: 1; */
    color: inherit;
    font-weight: 400;
    font-size: 16px;
    //color: $color-brand;
}

.back-one-level svg {
    transform: rotate(90deg);
    width: 18px;
    height: auto;
    aspect-ratio: 1 / 1;
}

/**
 * Special nav Cerimonie
 */
 /*! purgecss start ignore */
.wrap-nav nav#nav-special {
    width: auto;
    @media (min-width: 1024px) {
        display: flex;
        align-items: center;
    }
    .nav-special {
        --activemenu: #D92A64;
        --bordermenu: #D92A64;
        --textmenu: #fff;
        > li {
            position: relative;
            display: flex;
            flex-direction: column;
            @media (min-width: 1024px) {
                flex-direction: row;    
                justify-content: center;
                align-items: center;
            }
        }
        li.level0 {
            @media (min-width: 1024px) {
                ul {
                    display: none;
                }
            }
            & > a {
                text-align: center;
                @extend .font-heading;
                font-size: 32px;
                margin-bottom: 20px;
                @media (min-width: 1024px) {
                    margin: 0;
                    color: $color-brand;
                    font-family: $font-heading;
                    padding: 5px 0;
                    font-size: 18px;
                }
                line-height: normal;
            }
        }
        a.has-children:after {
            content: '';
        }
        @media (min-width: 1024px) {
            & > li:hover > ul {
                border-color: var(--bordermenu);
                border-width: 2px;
                border-style: solid;
                border-radius: 0 4px 4px 4px;
                display: flex;
                gap: 4px;
                position: absolute;
                top: 32px;
                right: -24px;
                overflow: visible;
                z-index: 999;
                background: #fff;
                padding: 8px 16px;
                width: var(--max-width-container);
                max-width: calc(100vw - 54px);
                box-shadow: rgba(124,58,237,.08) 0 3px 4px 0;
                li {
                    width: 100%;
                }
                li:last-child {
                    margin-right: 0;
                }
            }
        }
        
        .wrap-subcat {
            .sub-categories {
                ol {
                    padding: 0;
                    gap: 0;
                    flex-wrap: nowrap;
                    & > li a {
                        & > img {
                            max-width: 80px;
                            background: #f5f3ec;
                        }
                    }
                    
                }
            }
        }
        
        /** fix arrow menu */
        .block-product-slider .block-content .prev, .block-product-slider .block-content .next {
            z-index: 9;
        }
    }
}
/*! purgecss end ignore */