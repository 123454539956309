/*! purgecss start ignore */
// cookiebot
.ch2-block {
  z-index: 999999 !important;
}
.cms-index-index .show-all {
  // display: none;
}
iframe[src*="youtube"] {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  border: none;
}

.cms-page-view {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6,
  .page-title {
    margin-bottom: 16px;
  }
  .std {
    ol {
      list-style: decimal;
    }
    ul {
      list-style: inherit;
    }
    ol, ul {
      margin: 10px 20px;
      li {
        @extend .font-sm;
        line-height: 1.6;
        padding: 2px 0;
      }
    }
    a:not(.button) {
      text-decoration: underline;
      color: $color-brand;
    }
    dl {
      dt > a {
        text-decoration: underline;
        color: $color-text;
      }
      dd {
        margin-bottom: 16px;
        p {
          margin: 8px 0;
        }
      }
    }
  }
  &.cms-condizioni-acquisto {
    .std {
      ol {
        li {
          padding: 2px 0;
          a {
            font-size: $font-md;
            line-height: 1.6;
            text-decoration: underline;
            color: $color-body;
          }
        }
      }
    }    
  }
}

body:not(.firecheckout-index-index) {
  .col2-set,
  .box-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (min-width: 778px) {
      flex-direction: row;
    }
    /*gap: 8em;*/
    margin-bottom: 1em;
    .box {
      flex-basis: 100%;
    }
    .col-1, .col-2 {
      // fix bootstrap col width 8.33%
      width: auto;
      @media (min-width: 778px) {
        width: 50%;
      }
      flex: auto;
      //.box {
        // margin-top: 1em;
        h3 {
          font-size: 18px;
        }
        h4 {
          font-size: 16px;
        }
        a {
          //text-decoration: underline;
        }
      //}
    }
  }
}

.addresses-list {
  .item {
    margin-top: 1em;
    h3 {
      font-size: 18px;
    }
    a {
      text-decoration: underline;
      color: $color-brand;
    }
  }
}
.my-account {
  .welcome-msg {
    margin: 2em 0;
    .hello {
      font-size: 36px;
      @extend .font-heading;
      strong {
        font-weight: 400;
      }
      display: flex;
      align-items: center;
      gap: 8px;
      &::before {
        content: url('data:image/svg+xml,<svg viewBox="0 0 56 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.0542 1.05776C52.42 1.95676 55.6618 11.1102 54.0137 18.1524C53.2781 21.3125 51.3303 25.0039 46.9987 29.567L39.3709 36.4866C36.1562 39.0746 32.2742 41.8942 27.6157 44.9998C27.6157 44.9998 7.66064 31.7736 3.0158 21.8029C-1.62904 11.8185 5.75367 1.52087 14.1307 1.26207C24.7008 0.935157 27.7656 9.92517 28.2968 11.8185C28.324 11.9002 28.4466 11.8866 28.4603 11.8049C28.9098 9.8162 31.6204 0.172377 42.0542 1.05776Z" fill="%23D92A64"/><g style="mix-blend-mode:multiply" opacity="0.48"><path d="M54.0143 18.1525C53.2788 21.3127 51.3309 25.004 46.9994 29.5671L39.3715 36.4867C36.1569 39.0747 32.2748 41.8943 27.6164 45C27.6164 45 26.0636 43.9648 23.7207 42.2621C27.4393 39.7149 30.613 37.3857 33.31 35.2063L40.9379 28.2867C45.2695 23.7236 47.2173 20.0323 47.9529 16.8721C49.3014 11.0967 47.3671 3.89112 40.8971 1.00342C41.2648 1.00342 41.6599 1.01704 42.0549 1.0579C52.4206 1.95691 55.6625 11.1104 54.0143 18.1525Z" fill="%23D92A64"/></g><path d="M43.9196 32.564C43.1705 33.2586 43.9197 32.564 43.0615 33.2995" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M39.3709 36.4861C36.1562 39.0741 32.2742 41.8937 27.6157 44.9993C27.6157 44.9993 7.66064 31.7731 3.0158 21.8024C-1.62904 11.818 5.75367 1.52038 14.1307 1.26158C24.7008 0.934669 27.7656 9.92468 28.2968 11.818C28.324 11.8998 28.4466 11.8861 28.4603 11.8044C28.9098 9.81572 31.6204 0.171889 42.0542 1.05727C52.42 1.95627 55.6618 11.1097 54.0137 18.1519C53.2781 21.312 51.3303 25.0034 46.9987 29.5665L39.3709 36.4861Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        width: 53px;
        height: 44px;
      }
    }
  }
}

.customer-account {
  .skip-sidebar {
    margin-bottom: 8px;
  }
  .main .button {
    @extend .btn;
    @extend .btn-md;
  }
  .buttons-set {
    .button {
      font-size: $font-md;
      @extend .wide;
    }
  }
}

.customer-account-index,
.sales-order-history,
.checkout-onepage-success {

  #my-orders-table {    
    display: table;
    thead {
      display: none;
      @media (min-width: 778px) {
          display: table-header-group;
        }
    }
    & tr:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    tbody tr {
      display: grid;
      grid-template-rows: auto;
      grid-template-areas: 
        "number date date"
        "ship ship ship"
        "status total total"
        "view view view";
      border-radius: 8px 8px 0 0;
      .number {
        grid-area: number;
        
      }
    
      .date {
        grid-area: date;
      }
      .ship {
        grid-area: ship;
      }
      .total {
        grid-area: total;
      }
      .status {
        grid-area: status;
      }
      .view {
        background: #aeb7bd;
        @media (min-width: 778px) {
          background: inherit;
        }
      }
    
      td::before {
        content: attr(data-rwd-label);
        display: block;
        font-size: $font-xs;
        text-transform: uppercase;
      }
      @media (min-width: 778px) {
        td::before {
          display: none;
        }
      }
    
    
      .view {
        grid-area: view;
        text-align: center;
        .nobr {
          display: flex;
          justify-content: end;
          gap: 8px;
          @media (max-width: 778px) {
            width: 100%;
          }
          .separator {
            display: none;
          }
        }
      }
    
      margin: 16px auto;
      background: #f5f5f5;
      @media (min-width: 778px) {
        background: inherit;
        &:nth-child(2n) {
          background: #f5f5f5;
        }
        display: table-row;
        margin: 0;
      }
    }
    
    em {
      /* @extend .btn;
      @extend .btn-xs;
      @extend .btn-outline; */
      font-weight: bold;
      &[data-status="complete"] {
        color: $color-success-msg;
      }
      &[data-status="canceled"] {
        color: $color-error-msg;
      }
      &[data-status="processing"] {
        color: $color-notice-msg;
      }    
    }
    td.view {
      a {
        @extend .btn;
        @extend .btn-xs;
        @extend .btn-ui;
        &.link-reorder {
          @extend .btn-ui2;
        }
      }
    }
    .last > .nobr {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        a {
          min-width: 120px;
          //@extend .button;
          @extend .btn;
          @extend .btn-xs;
          @extend .btn-ui;
          &.link-reorder {
            @extend .btn-ui2;
          }
        }
        .separator {
          display: none;
        }

    }
  }
}


 .customer-account {
  .order-info {
    dt {
      display: none;
    }
    dd > ul {
      display: flex;
      flex-wrap: wrap;
      font-size: 16px;
      gap: 16px;
      margin: 16px auto;
      li {
        color: $color-brand;
        font-weight: 900;
        text-transform: uppercase;
      }
      li.current {
        position: relative;
        &:after {
          border-bottom-color: inherit;
          border-bottom-style: solid;
          border-bottom-width: 1px;
          bottom: 0px;
          content: "";
          display: block;
          height: 0;
          left: 0;
          position: absolute;
          transition: border .15s ease-in-out;
          width: 100%;
        }
      }
    }
  }
}

.sales-order-view {
  .order-date {
    margin: 8px auto;
  }
}

#header-account main,
/* #account-navigation main, */
.customer-account .order-info dd > ul {
  a {
    transition: color .15s ease-in-out;
    position: relative;

    &:after {
      border-bottom-color: transparent;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      bottom: -3px;
      content: "";
      display: block;
      height: 0;
      left: 0;
      position: absolute;
      transition: border .15s ease-in-out;
      width: 100%;
    }

    &:hover {
      text-decoration: none;

      &:after {
        //border-bottom-color: inherit !important;
      }
    }
  }

  
}

#wishlist-table {
  .cart-cell {
    margin-bottom: 8px;
    button {
      @extend .wide;
    }
  }
  .link-edit {
    @extend .btn;
    @extend .btn-xs;
    @extend .btn-ui;
  }
  .customer-wishlist-item-info textarea {
    width: 80%;
    height: 80px;
  }
}
#wishlist-view-form {
  .buttons-set {
    flex-direction: row;
    flex-wrap: nowrap;
    .button {
      justify-content: center;
      @extend .btn;
      @extend .btn-md;
      @extend .btn-ui2;
    }
  }
}

.nobr {
    display: inline-block;
    width: max-content;
}

// .box-account {
  .box-head,
  .box-title,
  .title-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;      
    gap: 4px;
    @media (min-width: 778px) {
      gap: 8px;
      flex-direction: row;
    }
    align-items: start;
    margin-bottom: 8px;
  }

  .box-title,
  .title-buttons {
    a {
        // @extend .btn;
        //@extend .button;
        //@extend .btn-xs;
        @extend .btn;
        @extend .btn-xs;
        @extend .btn-ui;
        &.link-reorder {
          order: 2;
          @extend .btn-ui2;
        }
    }
    button {
      @extend .btn;
      @extend .btn-md;
      @extend .btn-ui2;     
    }
    .separator {
      display: none;
    }
  }
// }

.newsletter-manage-index {
  .buttons-set {
    align-items: start;
    flex-direction: column-reverse;
  }
}

.box-head {
  margin-bottom: 16px;
  a {
      @extend .btn;
      @extend .btn-sm;
      //@extend .btn-ui2;
      background-color: $color-brand;
			border-color: $color-brand;
      color: #fff;
      &.link-print {
        @extend .btn-ui2;
      }
  }
}
.title-buttons h1 {
  flex-basis: 100%;
}

.title-buttons {
  button,
  a {
    min-width: max-content;
  }
}

.header-language-background .welcome-msg,
.form-language select,
.form-language select option {
	font-size: 12px;
  @media (min-width: 887px) {
    // font-size: 14px;
  }
}
.form-language {
	display: inline-block;
	float: right;
	label {
		display: none;
	}
}
.validation-advice,
.note {
	font-size: $font-xs;
	color: $color-muted;
}
// .category-description {
// 	padding: .5em;
// }
.validation-advice {
	color: $color-danger;
}
.no-display {
	display: none;
}
.a-left {
  text-align: left;
}
.a-right {
  text-align: right;
}
.a-center {
  text-align: center;
}
label.required em, span.required em {
  color: #df280a;
  margin-right: 4px;
}
body:not(.catalog-product-view) {
  label.required em, span.required em {
    display: none;
  }
  label.required::after, span.required::after {
    content: ' *';
    color: #df280a;
    font-weight: normal;
    // font-family: "Helvetica Neue", Verdana, Arial, sans-serif;
    font-size: 14px;
  }
}
input[type="file"] ~ p {
  @extend .font-xs;
  @extend .text-muted;
}
.product-options {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  dl {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    gap: 8px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    
    dt:not(:first-of-type) {
      padding-top: 12px;
    }
    @media (min-width: 778px) {   
      /* display: grid;
      grid-template-columns: minmax(30%, max-content) 1fr; */
      gap: 16px;
    }
  }
  dd {
    &:last-child {
      grid-column: 1 / -1;
      grid-row: 2;
    }
    display: flex;
    flex-direction: column;
    gap: 4px;
    & > * {
      flex: 1;
      align-items: center;
      gap: 8px;
    }
    textarea {
      width: 100%;
    }
  }
}
.product-options p.required {
  /* color: #df280a; */
  display: none;

}
.product-options label.required em {
  display: none;
}
p.required, .validation-advice,
.item-msg.error {
  margin: 4px 0 0;
  color: #df280a;
  font-size: $font-xs;
  line-height: normal;
}
.item-options {
  font-size: $font-sm;
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-column-gap: 8px;
  // margin: 8px 0;
  // max-width: 140px;
  dt {
    // font-weight: 600;
    color: $color-text;
    float: left;
    clear: both;
    margin-right: 6px;
    line-height: initial;
  }
  dd {
    color: $color-brand;
    float: left;
    line-height: initial;
  }
}
// dl {
//   display: flex;
//   flex-wrap: wrap;
//   width: 100%;
// }
// dt {
//   font-weight: bold;  
//   width: 25%;
//   padding-top: 1em;  
// }
// dd {
//   display: flex;
//   margin: 0;
//   padding-top: 1em;
// }

// dd + dd {
//   width: 100%; 
//   padding-top: 0;  
// }
// dt + dd:not(:nth-child(2)),
// dt:first-child + dd:nth-child(2) {
//   width: 75%;
// }
// dd + dd::before {
//   width: 25%;
//   content: "";
//   padding-top: 0
// }
dt label {
  font-weight: 500;
  color: $color-body;
  font-size: 14px;
  .select-label {
    font-size: $font-xs;
    text-transform: none;
  }
}




.product-pricing .messages li {
  font-size: $font-xs;
  font-weight: normal;
}


.availability {
  // text-align: center;
  span {
    font-weight: 700;
  }
}
.availability.in-stock {
  span {
    color: $color-price;
  }
}
.availability.out-of-stock {
  color: $color-danger;
}
.availability.out-of-stock.preorder {
  color: orange;
}

// .category-products .availability {
//   position: absolute;
//   font-size: 13px;
//   bottom: 2.8em;

//   @media only screen and (min-width:576px){
//     position: relative;
//     padding: .85em 1em;
//     font-size: initial;
//     bottom: inherit;
//   }

// }
.category-image {
  text-align: center;
  img {
    /* border-radius: 8px; */
    max-width: 100%;
    height: auto;
  }
}
// reset product description styles
body:not(.cms-index-index) 
.product-view
.std {
  // margin-top:10px;
  // font-size: inherit !important;
  font-family: $font-heading !important;
  h1 {
    // font-size: inherit !important;
  }
  span,
  strong,
  font {
    // font-size: inherit !important;
    font-family: $font-heading !important;
  }
  ul:not(.products-grid) {
    list-style: disc;
    margin: 10px 20px;
    li {
      @extend .font-sm;
      line-height: 1.6;
      padding: 2px 0;
    }
  }
  p {
    font-weight: 500;
  }
}
.product-collateral {
  text-align: left;
  .std {
    /* max-width: 800px; */
    p {
      line-height: 1.8;
      margin: 8px 0;
    }
    /* margin: 0 auto; */
  }
}


.box-collateral {

  margin-bottom: 16px;
  table {
    margin-top: 8px;
    width: 100% !important;
    max-width: 600px;
    tr,
    tbody {
      background-color: #fff;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    tr td:first-child,
    tr th:first-child {
      font-weight: 700;
      color: $color-text;
      // color: $color-brand;
    }
    tr td {
      // color: $color-muted;
      text-align: left;
    }
  }
}

.product-attributes,
.product-collateral {
  table {
      display: block;
      overflow: auto;
      width: auto !important;
      @media (min-width:576px){
        display: table;
        width: 100% !important;
        max-width: 600px;
      }
      display: block;
      height: auto !important;
      margin: 0 !important;
      tr,
      tbody {
        background-color: #fff;
      }
      tr:nth-child(even) {
        background-color: #f2f2f2;
      }
      p {
        font-weight: inherit !important;
        font-size: inherit !important;
        color: inherit !important;
      }
      tbody {
        display: inline-block;
        width: fit-content !important;
        border: 1px solid #eee;
        border-radius: 4px;
        padding: 16px;
        tr {
          width: fit-content !important;
        }
      }
      tr:first-child td {
        font-weight: 700;
        font-weight: bold;
      }
      tr td {
        padding: 8px 16px;
        color: $color-text;
        width: fit-content !important;
        border: 0;
        border-bottom: 1px solid #928f8f;
        text-align: left;
        span,
        strong,
        font {
          font-size: inherit !important;
        }
      }
    }
  }
  .product-attributes .data {
    p,
    span,
    strong {
      //font-weight: inherit !important;
      font-size: inherit !important;
      //color: inherit !important;
    }
}

.remember-me-popup {
  margin: 8px auto;
  padding: 8px 16px;
  border: 1px solid #eee;
  display: none;
  &.show {
    display: block;
  }
  .remember-me-popup-close-button {
    display: none;
  }
  .remember-me-popup-head {
    display: flex;
    justify-content: space-between;
  }
}
.remember-me-box {
  .link-tip.hide {
    display: none;
  }
}




.data-table {
  display: block;
  overflow: auto;
  @media (min-width:576px){
    display: table;
  }
  /* tr,
  tbody {
    background-color: #fff;
  } */
}
table {
  width: 100%;
  margin-bottom: 1.5rem;
  border-collapse: collapse;
  vertical-align: middle;

  td,
  th {
    text-align: left;
    padding: 8px 8px;
    //border: 1px solid $border-color;
    vertical-align: middle;
  }
 

  thead>tr>th {
    background: $color-grey-1;
    color: white; 
    vertical-align: middle;
    border-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  &.clean {

    td,
    th {
      border: 0;
    }
  }

  .product-thumbnail img {
    max-width: 80px;
  }

  /* reset */
  h1,
  h2,
  h3 {
    font-size: inherit !important;
  }
}

.checkoutsuccess-block-wrapper {
  .box-payment {
    .box-content {
      display: flex;
      flex-direction: column;
      table td {
        padding: 0;
      }
    }
  }
}

.review-heading {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
  p {
    font-size: 14px;
    line-height: 16px;
  }
}
.review-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @media (min-width: 778px) {
    flex-direction: row;    
    & > * {
      flex-basis: 45%;
    }
  }
  gap: 16px;
  flex-wrap: wrap;
}
.product-review {
  display: grid;
  grid-template-areas:
    'title   title  ' 
    'img     review ' 
    'buttons buttons';
  .page-title {
    grid-area: title;
  }
  .product-img-box {
    grid-area: img;
  }
  .review-item {
    grid-area: review;
  }
  .buttons-set {
    grid-area: buttons;
  }
}
.review-item {


  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
  padding: 16px;
  
  .ratings-table {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 4px;
    .label {
      /* display: none; */
      font-weight: 500;
    }
    margin-bottom: 16px;
  }
  .review-title {
    font-weight: 600;
  }
  .review-meta {
    
    display: flex;
    flex-direction: column;
    @media (min-width: 778px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 8px;  
      .review-author {
        font-size: 20px;
        font-weight: 600;
      }
    }

    margin-bottom: 16px;

  }
  .review-date {
    font-size: 12px;
    color: $color-muted;
  }
  .review-body {
    font-size: $font-sm;
  }
}
.box-reviews .form-add {
  /* max-width: fit-content;
  margin: 0 auto; */
  max-width: max(50%, 650px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #fff;
  border: 1px solid $color-brand;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
  padding: 16px;
  &:has(> .review-nologged) {
    max-width: fit-content;
  }
  
  .review-nologged a {
    text-decoration: underline;
  }
  h3 > span {
    color: $color-brand;
  }
  .review-summary-table {
    overflow: hidden;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: max-content;
    th,
    td.value {
      text-align: center;
    }

    .rate {
      display: flex;  
      flex-direction: column;
      gap: 4px;      
      @media (min-width: 778px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 16px;
      }
      strong {
        font-weight: 400;
      }
    }
  }
  .fieldset {
    margin: 16px 0;
  }
  .rating-box {
    display: inline-flex;
  }
  
  textarea {
    width: 100%;
    min-height: 150px;
  }
  .button {
    @extend .wide;
  }
}


.star-rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  --star-rating__icon-inactive: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="%23999"><path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" /></svg>');
}

.star-rating__value {
  position: fixed;
  opacity: 0;
  pointer-events: none;
}

.star-rating__label {
  cursor: pointer;
  display: inline-block;
  font-size: 0;
  width: 32px;
  height: 32px;
}

.star-rating__label:before {
  content: var(--star-rating__icon-inactive);
  display: inline-block;
  width: 32px;
  height: 32px;
}

.star-rating__value:checked ~ .star-rating__label {
  content: var(--star-rating__icon-active);
}

@media (hover: hover) and (pointer: fine) {
  .star-rating__label:hover,
  .star-rating__label:hover ~ .star-rating__label {
    content: var(--star-rating__icon-active);
  } 
  .star-rating__value:checked + .star-rating__label:hover,
  .star-rating__value:checked + .star-rating__label:hover ~ .star-rating__label,
  .star-rating__value:checked ~ .star-rating__label:hover,
  .star-rating__value:checked ~ .star-rating__label:hover ~ .star-rating__label,
  .star-rating__label:hover ~ .star-rating__value:checked ~ .star-rating__label {
    content: var(--star-rating__icon-active);
  }
}

.product-view .product-essential .product-img-box .zoomview {
  display: block;
  width: 100%;
}
body:has(.product-img-box.zoomOpen) {
  overflow: hidden;
}
.product-view .product-essential .product-img-box.zoomOpen {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  max-width: inherit;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  /* background-color: rgba(0,0,0,.85);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px); */
  background-color: white;
  
  svg {
    //color: white !important;
    color: $color-brand !important;
  }
  .product-badges {
    display: none;
  }
  .product-image {
    flex: 1;
    border: none;
    border-radius: none;
    overflow: auto;
    width: auto;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-image-gallery {
    display: flex;
    width: 100vw;
    max-height: 100%;
    border: 0;
    border-radius: 0;
  }
  img {
    aspect-ratio: inherit;
    max-width: 100%;
    width: auto;
    max-height: 100%;
  }

  
  @media (min-width: 778px) {  
    .product-image-thumbs {
      display: flex;
      img {
        max-width: 75px;
      }
    }
  }
}

.panel-group {
  border-radius: 8px 8px 0 0;
  background: #f5f5f5;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 20px;
}
.panel-heading {
  .panel-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  :after {
    display: inline-block;
    content: url('data:image/svg+xml,<svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L9 9L17 1" stroke="black" stroke-width="2" stroke-linecap="round"/></svg>');
    
  }
  &.active :after {
    content: url('data:image/svg+xml,<svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 10L9 2L1 10" stroke="black" stroke-width="2" stroke-linecap="round"/></svg>')
  }

  &:not(:first-child) {
    border-top: 1px solid #ececec;
  }
  padding: 8px 0;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  a {
    text-decoration: none !important;
  }
  &.active a {
    color: $color-brand;
  }
}
.panel-body {
  display: none;
  padding: 8px;
  &.active {
    display: block;
  }
}
.whatsapp_widget {
  position: fixed; 
  bottom: 20px;
  right: 20px;
  z-index: 999;
  a {
    display: flex;
    position: relative;
    align-items: center;
    gap: 4px;
    @media (min-width: 768px) {
      padding: 6px;
      border-radius: 6px;
      box-shadow: 6px 4px 10px rgba(0,0,0,.3);
      background: #eee;
      color: black;
    }
    span {
      display: none;
      @media (min-width: 768px) {
        display: inherit;
      }
    }
    svg {
      width: 40px;
      height: 40px;
      @media (min-width: 768px) {
        width: 24px;
        height: 24px;
      }
      filter: drop-shadow(6px 4px 10px rgba(0,0,0,.3));
      @media (min-width: 768px) {
        filter: none;
      }
      .st0{fill:#25D366;}
      .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
    }
  }
}
@media (max-width: 768px) {
  .catalog-product-view .whatsapp_widget {
    display: none !important;
  }
}

#customer-recent-reviews {
  margin-top: 0;
  .review-heading {
    gap: unset;
    max-width: var(--max-width-container);
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .block-content {
    max-width: none;
  }
  #customer-recent-reviews-items.products-grid {
    --grid-column-count: 5;
    --grid-item--max-width: 260px;
    --grid-item--min-width: 260px;
    margin: 0 10px;
    .item.review-item {
      border-color: #03916f;
      main {
        flex: 1;
      }
      //justify-content: unset;
      .review-meta {
        margin-bottom: 4px;
      }
    }
    .product-name {
      
      @extend .t-truncate;
			@extend .t-truncate-2;
      /* font-weight: 600; */
      line-height: normal;
    }
    .review-title {
      @extend .t-truncate;
			@extend .t-truncate-2;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 4px;
    }
    .review-content {
      @extend .t-truncate;
			@extend .t-truncate-2;
      line-height: normal;
    }
  }
}

.payment-list li {
  line-height: 0;
}

@media (min-width: 1024px) {
  .customer-account .col2-left-layout > .main {
    grid-template-columns: auto auto;
    gap: 0 20px;
    table {
      margin: 0;
    }
    .pages ol {
      padding-right: 0;
    }
  }
}
body.customer-account-login {
  .account-login {
    .col2-set {
      flex-direction: column-reverse;
      @media (min-width: 778px) {
        flex-direction: row-reverse;
      }
      justify-content: center;
      gap: 40px;
      & > * {

        .content {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .form-list {
            margin: 0;
          }
        }

        padding: 20px;
        max-width: max-content;

        @extend .background-highlight2;
        border-radius: 8px;
        text-align: center;    
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
          @extend .h1;
          @extend .font-heading;
        }
        .buttons-set {
          margin-top: 20px;
        }
        .button {
          @extend .btn-cta;
          width: 100%;
          text-transform: uppercase;
        }
        .f-left {
          text-align: left;
          font-weight: 700;
          font-size: 14px;
          text-decoration: underline;
        }
      }
    }
  }  
}
.block-account {
  > main.block-content {
    border-radius: 8px;
    border: 1px solid #DADADA;
    box-shadow: -2px 4px 12px 0px rgba(85, 85, 85, 0.20);
    padding: 20px 0;
    li {
      margin-inline: 40px;
      padding-block: 8px;
      border-bottom: 1px solid #DADADA;
      &.current {
        border-top: 1px solid #DADADA;
        background-color: #F5E7E6;
        margin-inline: 0;
        padding-inline: 40px;
        & > * {
          color: initial;
        }
      }
      &:has(+ li.current) {
        border: 0 !important;
      }
      &:last-child {
        border-bottom: 0;
      }
      display: flex;
      align-items: center;
      gap: 12px;
      &::before {
        width: 36px;
        height: 40px;
      }
      &:has(a[href*="customer/account/edit/"])::before {
        content: url('data:image/svg+xml,<svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 29C11.4732 26.3768 14.831 24.7647 18.5294 24.7647C22.2279 24.7647 25.5856 26.3768 28.0588 29M23.2941 15.7647C23.2941 18.3962 21.1609 20.5294 18.5294 20.5294C15.8979 20.5294 13.7647 18.3962 13.7647 15.7647C13.7647 13.1332 15.8979 11 18.5294 11C21.1609 11 23.2941 13.1332 23.2941 15.7647Z" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
      &:has(a[href*="customer/address/"])::before {
        content: url('data:image/svg+xml,<svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.9941 17.3687C26.9941 22.3392 17.9941 30.8687 17.9941 30.8687C17.9941 30.8687 8.99414 22.3392 8.99414 17.3687C8.99414 12.3981 13.0236 8.36865 17.9941 8.36865C22.9647 8.36865 26.9941 12.3981 26.9941 17.3687Z" stroke="%23D92A64" stroke-width="1.5"/><path d="M17.9941 18.4937C18.6155 18.4937 19.1191 17.99 19.1191 17.3687C19.1191 16.7473 18.6155 16.2437 17.9941 16.2437C17.3728 16.2437 16.8691 16.7473 16.8691 17.3687C16.8691 17.99 17.3728 18.4937 17.9941 18.4937Z" fill="%23D92A64" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
      &:has(a[href*="sales/order/history/"])::before {
        content: url('data:image/svg+xml,<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 15.4772L17.7054 19.3463L18.2946 18.0204L9.58926 14.1514L9 15.4772ZM18.7254 29.3232V18.6833H17.2746V29.3232H18.7254ZM18.2946 19.3463L27 15.4772L26.4107 14.1514L17.7054 18.0204L18.2946 19.3463Z" fill="%23D92A64"/><path d="M9.29492 25.0771V15.1915C9.29492 14.9621 9.42999 14.7543 9.63958 14.6611L17.7646 11.05C17.9147 10.9833 18.0859 10.9833 18.236 11.05L26.361 14.6611C26.5706 14.7543 26.7057 14.9621 26.7057 15.1915V25.0771C26.7057 25.3064 26.5706 25.5143 26.361 25.6074L18.236 29.2185C18.0859 29.2852 17.9147 29.2852 17.7646 29.2185L9.63958 25.6074C9.42999 25.5143 9.29492 25.3064 9.29492 25.0771Z" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.6475 12.8799L22.0082 16.5958C22.2178 16.6889 22.3528 16.8967 22.3528 17.1261V20.618" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
      &:has(a[href*="customer/savedcards/"])::before {
        content: url('data:image/svg+xml,<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 23C13.5523 23 14 22.5523 14 22C14 21.4477 13.5523 21 13 21C12.4477 21 12 21.4477 12 22C12 22.5523 12.4477 23 13 23Z" fill="%23D92A64" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8 17V13.6C8 13.2686 8.26863 13 8.6 13H27.4C27.7314 13 28 13.2686 28 13.6V17M8 17V26.4C8 26.7314 8.26863 27 8.6 27H27.4C27.7314 27 28 26.7314 28 26.4V17M8 17H28" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
      &:has(a[href*="wishlist/"])::before {
        content: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.1052 13.1428C18.3057 10.7977 15.3051 10.1669 13.0506 12.3143C10.796 14.4617 10.4786 18.052 12.2491 20.5918C13.3019 22.102 15.8806 24.8102 17.7982 26.7576C18.5897 27.5615 18.9855 27.9634 19.4619 28.1264C19.8695 28.2658 20.3409 28.2659 20.7484 28.1264C21.2248 27.9634 21.6206 27.5615 22.4122 26.7576C24.3297 24.8102 26.9084 22.102 27.9612 20.5918C29.7317 18.052 29.4531 14.4391 27.1598 12.3143C24.8665 10.1895 21.9046 10.7977 20.1052 13.1428Z" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
      &:has(a[href*="customer/account/logout/"])::before {
        content: url('data:image/svg+xml,<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 20H25M25 20L22 23M25 20L22 17" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M25 14V13C25 11.8954 24.1046 11 23 11H13C11.8954 11 11 11.8954 11 13V27C11 28.1046 11.8954 29 13 29H23C24.1046 29 25 28.1046 25 27V26" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
      
      a[href*="customer/account/logout/"] {
        color: $color-brand;
      }
      a {
        font-size: 18px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        
        

        &::after {
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 36" fill="none"><path d="M14.1123 11L20.4083 18L14.1123 25" stroke="%23333333" stroke-width="2" stroke-linecap="round"/></svg>');
          width: 33px;
          height: 36px;
        }
      }
    }
  }
}
.need-help {
  display: flex;
  flex-direction: column;
  gap: 40px;

}
.benefits {
  list-style: none;
  margin: 8px 4px;
  padding: initial;
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > li {
    display: flex;
    align-items: center;
    gap: 4px;
    &::before {
      width: 40px;
      height: 40px;
    }
    &:nth-child(1)::before {
      content: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 15.4772L19.7054 19.3463L20.2946 18.0204L11.5893 14.1514L11 15.4772ZM20.7254 29.3232V18.6833H19.2746V29.3232H20.7254ZM20.2946 19.3463L29 15.4772L28.4107 14.1514L19.7054 18.0204L20.2946 19.3463Z" fill="%23D92A64"/><path d="M11.2949 25.0771V15.1915C11.2949 14.9621 11.43 14.7543 11.6396 14.6611L19.7646 11.05C19.9147 10.9833 20.0859 10.9833 20.236 11.05L28.361 14.6611C28.5706 14.7543 28.7057 14.9621 28.7057 15.1915V25.0771C28.7057 25.3064 28.5706 25.5143 28.361 25.6074L20.236 29.2185C20.0859 29.2852 19.9147 29.2852 19.7646 29.2185L11.6396 25.6074C11.43 25.5143 11.2949 25.3064 11.2949 25.0771Z" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.6475 12.8799L24.0082 16.5958C24.2178 16.6889 24.3528 16.8967 24.3528 17.1261V20.618" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    &:nth-child(2)::before {
      content: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.1052 13.1428C18.3057 10.7977 15.3051 10.1669 13.0506 12.3143C10.796 14.4617 10.4786 18.052 12.2491 20.5918C13.3019 22.102 15.8806 24.8102 17.7982 26.7576C18.5897 27.5615 18.9855 27.9634 19.4619 28.1264C19.8695 28.2658 20.3409 28.2659 20.7484 28.1264C21.2248 27.9634 21.6206 27.5615 22.4122 26.7576C24.3297 24.8102 26.9084 22.102 27.9612 20.5918C29.7317 18.052 29.4531 14.4391 27.1598 12.3143C24.8665 10.1895 21.9046 10.7977 20.1052 13.1428Z" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
    &:nth-child(3)::before {
      content: url('data:image/svg+xml,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.0713 17.2856L21.8927 20.4999L26.7142 17.2856" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.6426 22.1069H12.7854" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.5 18.8926H12.7857" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.7861 15.6786V15.1429C12.7861 13.9594 13.7455 13 14.929 13H28.8576C30.0411 13 31.0004 13.9594 31.0004 15.1429V25.8572C31.0004 27.0407 30.0411 28 28.8576 28H14.929C13.7455 28 12.7861 27.0407 12.7861 25.8572V25.3214" stroke="%23D92A64" stroke-width="1.5" stroke-linecap="round"/></svg>');
    }
}
}
button[popovertarget] {
  border: 0;
  background: none;
  font: inherit;
  padding: 0;
  display: inline-block;
  text-decoration-style: dashed;
  text-decoration-line: underline;
  text-decoration-color: #42a5f5;
  text-underline-offset: 2px;
  overflow: visible;
  color: #1e88e5;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='100' title='question-circle'%3E%3Cpath fill='%231e88e5' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z' /%3E%3C/svg%3E");
  background-size: 0.8em;
  background-repeat: no-repeat;
  background-position: center right 1px;
  padding-right: 1.1rem;

  &:focus,
  &:hover {
    text-decoration-color: lightblue;
    text-decoration-style: solid;
  }
}

.popover {

  max-width: 268px;
  width: 100%;
  
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 15px;
  background: #fff;
  border: 1px solid #DADADA;
  box-shadow: -2px 4px 12px 0px rgba(85, 85, 85, 0.20);
  border-radius: 8px;

  .popover__title {
    @extend .font-heading;
    font-size: 22px;
    font-style: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  img {
    max-width: fit-content;
    align-self: center;
  }
}

/*! purgecss end ignore */