$color-success-msg: #155724;
$bg-success-msg: #d4edda;
$border-success-msg: #c3e6cb;

$color-error-msg: #721c24;
$bg-error-msg: #ffcdcd;
$border-error-msg: #ff959e;

$color-warning-msg: #856404;
$bg-warning-msg: #fff3cd;
$border-warning-msg: #ffeeba;

$color-notice-msg: #000;
$bg-notice-msg: #d1ecf1;
$border-notice-msg: #bee5eb;
/*! purgecss start ignore */
.messages {
    margin: 8px auto;

    li {
        list-style: none;
        margin-left: 0;

        li {
            position: relative;
            padding: 8px 16px;
            margin-bottom: 24px;
            border: 2px solid transparent;
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
            border-radius: 8px;
            font-size: 14px;
            font-weight: bold;
            line-height: initial;

            display: flex;
            align-items: center;
            gap: 8px;
        }

        li:before {
            display: block;
            width: 24px;
            height: 24px;
            aspect-ratio: 1 / 1;

            animation: tada 2s linear forwards;
            animation-iteration-count: 1;
            opacity: 0.75;
        }

        &.success-msg li {
            color: $color-success-msg;
            background-color: $bg-success-msg;
            border-color: $border-success-msg;
            &:before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'/%3E%3C/svg%3E");
            }
        }

        &.error-msg li {
            color: $color-error-msg;
            background-color: $bg-error-msg;
            border-color: $border-error-msg;
            &:before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M16.971 0h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-4.971 19.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25zm.5-4.25h-1l-1-10h3l-1 10z'/%3E%3C/svg%3E");
            }
        }

        &.warning-msg li {
            color: $color-warning-msg;
            background-color: $bg-warning-msg;
            border-color: $border-warning-msg;
            &:before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.31 7.526c-.099-.807.528-1.526 1.348-1.526.771 0 1.377.676 1.28 1.451l-.757 6.053c-.035.283-.276.496-.561.496s-.526-.213-.562-.496l-.748-5.978zm1.31 10.724c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'/%3E%3C/svg%3E");
            }
        }

        .notice-inner,
        &.note-msg li,
        &.notice-msg li {
            color: $color-notice-msg;
            background-color: $bg-notice-msg;
            border-color: $border-notice-msg;
            &:before {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M13 16h-2v-6h2v6zm-1-10.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25zm0-2.75c5.514 0 10 3.592 10 8.007 0 4.917-5.145 7.961-9.91 7.961-1.937 0-3.383-.397-4.394-.644-1 .613-1.595 1.037-4.272 1.82.535-1.373.723-2.748.602-4.265-.838-1-2.025-2.4-2.025-4.872-.001-4.415 4.485-8.007 9.999-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.738 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.418.345 2.775.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006z'/%3E%3C/svg%3E");
                display: block;
                width: 24px;
                height: 24px;
            }
        }
    }
}

.firecheckout-index-index .messages li.error-msg li {
    color: $color-warning-msg !important;
    background-color: $bg-warning-msg !important;
    border-color: $border-warning-msg !important;

    &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.31 7.526c-.099-.807.528-1.526 1.348-1.526.771 0 1.377.676 1.28 1.451l-.757 6.053c-.035.283-.276.496-.561.496s-.526-.213-.562-.496l-.748-5.978zm1.31 10.724c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'/%3E%3C/svg%3E") !important;
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    10%,
    20% {
        -webkit-transform: scale(.9) rotate(-8deg);
        transform: scale(.9) rotate(-8deg)
    }

    30%,
    50%,
    70% {
        -webkit-transform: scale(1.3) rotate(8deg);
        transform: scale(1.3) rotate(8deg)
    }

    40%,
    60% {
        -webkit-transform: scale(1.3) rotate(-8deg);
        transform: scale(1.3) rotate(-8deg)
    }

    100%,
    80% {
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0)
    }
}

.global-site-notice>* {
    position: relative;
    padding: 8px 16px;
    /* border: 1px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    background-color: $bg-notice-msg;
    border-color: $border-notice-msg; */

    p {
        font-size: $font-xs;
        /* color: $color-notice-msg; */
        margin: 0;
        text-align: center;
    }
}
/*! purgecss end ignore */

