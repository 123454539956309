
@media (min-width: 1024px) {
	body.header-sticked {
		.block-layered-nav {
			max-height: calc(100vh - 180px);
			transition: max-height .2s ease-in-out;
		}
	}
}


/*! purgecss start ignore */
.block-layered-nav {
	
	justify-content: space-between;
	overflow: unset;
	max-height: 100%;

	main {
		@media (min-width: 1024px) {
			background-color: #F5E7E6 !important;
		}
		overflow: auto;
		@include scrollbar-mini($color-track: #eee, $color-thumb: #ccc);
		height: 100%;
	}
	footer {
		
	}
	
	.block-subtitle,
	.block-subtitle--filter,
	.block-title {
		display: none;
	}
	@media (min-width: 1024px) {
		max-height: calc(100vh - 280px);
		& header {
			/* display: none; */
			h1 {
				display: flex;
				align-items: center;
				gap: 4px;
				font-size: 24px;
				line-height: 28px;
				font-weight: 700;
			}
		}
		& main {
			padding: 20px 40px;
		}
	}
	.block-title,
	.block-subtitle {
		color: $color-brand-2;
		font-weight: 600;
		font-size: $font-lg;
	}
	.currently {
		.block-subtitle {
			font-weight: 600;
		}	
		ol {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			gap: 4px;
			margin-bottom: 4px;
			& > li {
				display: inline-flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 4px;
				font-size: 13px;
				color: $color-brand;
				font-weight: bold;
				.label {
					color: $color-body;
				}
				.value {
				}
				.btn-remove {
					visibility: hidden;
					display: block;
					
					width: 18px;
					height: 18px;
					position: relative;
					&:after {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 18px;
						height: 18px;
						visibility: visible;
						/* content: url('data:image/svg+xml; utf8,\
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">\
							<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>\
						</svg>'); */
						content: url('data:image/svg+xml; utf8,\
						<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">\
							<path d="M24 24h-24v-24h24v24zm-1-23h-22v22h22v-22zm-3 6.435l-10.005 10.565-4.995-5.866.761-.648 4.271 5.015 9.24-9.751.728.685z" />\
						</svg>');
						background: #fff;
					}
				}
			}
		}
	}
	.actions {
		display: flex;
		justify-content: space-between;
		gap: 16px;
		
		& > a {
			flex-grow: 1;
			@extend .btn;
			@extend .btn-xs;
			@extend .btn-ui2;
			margin-bottom: 12px;
		}
	}
	#narrow-by-list {
		display: flex;
 	 	flex-direction: column;
		dt {
			color: $color-body;
			font-size: 18px;
			font-weight: 700;
			/* &.panel-heading {
				background: $color-grey-9;
			} */
			/* margin-top: 8px; */
			&.panel-heading {
				padding-bottom: 16px;
				&.active {
					padding-bottom: 0;
				}
			}
		}
		dd {
			padding: 16px 0;
			font-size: 18px;
			// margin-left: 8px;
			/* margin-top: 8px; */
			max-height: 250px;
  			overflow: auto;
			scrollbar-color: initial;
			.count {
				display: none;
			}
			
			ol.configurable-swatch-list {
				

				/* display: flex;
				gap: 4px; */
				/* display: flex;
				gap: 4px; */
				/* a {
					display: flex;
					align-items: center;
					flex-direction: column;
					& > * {
						line-height: normal;
					}
				} */
			}
		
			ol:not(.configurable-swatch-list) {
				display: flex;
				flex-direction: column;
				gap: 2px;
				/* li:not(:last-child) {
					border-bottom: 1px solid gainsboro;
				} */
				li {
					display: flex;
					gap: 4px;
					font-size: 14px;
					& > a {
				
						display: inline-flex;
						align-items: center;
						gap: 8px;
						font-size: 14px;
					
						&::before {
							content: '';
							border: 1px solid #aaa;
							background: #fff;
							height: 16px;
							width: 16px;
							aspect-ratio: 1/1;
							display: inline-block;
							vertical-align: middle;
							// border-radius: 2px;
							box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05);
						}
					}

					details {
						summary {
							cursor: pointer;
							padding: 0;
							border: 0;
							font-size: inherit;
							display: inline-flex;
							align-items: center;
							gap: 8px;
							font-weight: normal;
							&::marker {
								content: unset;
							}
							&::after {
								content: unset;
							}
							&::before {
								content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="17 11 7 18" fill="none">\
									<path d="m17 13 7 7-7 7" stroke="%23d92a64" stroke-width="2.5" stroke-linecap="round"/>\
								</svg>');
								width: 16px;
								height: 16px;
								aspect-ratio: 1/1;
							}
							user-select: none;
						}
						&[open] {
							summary {
								color: $color-brand;
								font-weight: 700;
								&::before {
									transform: rotate(90deg);
								}							

							}
						}
					}
				}
			}
			li label {
				font-size: $font-sm;
				font-weight: normal;
				cursor: pointer;
			}
		}
	}
}
/*! purgecss end ignore */