/*Page > Homepage */
/*! purgecss start ignore */
.cms-index-index {
	.container {
		max-width: var(--max-width-container);
		padding: inherit 16px;
		position: relative;
		width: calc(100% - 32px);
		margin-left: auto;
		margin-right: auto;
	}
	.container-left {
		display: flex;
		flex-direction: column;
		gap: 20px;
		@media (min-width: 778px){
			flex-direction: row;
		}
		justify-content: space-between;
		align-items: center;
		@media (min-width: 1200px) {
			padding-right: calc( calc(100% - var(--max-width-container)) / 2 );
		}
	}

	
	.banner-promo__text {
		padding-left: 16px;
		padding-right: 16px;
		@media (min-width: 778px){
			max-width: 487px;
			padding: 0;
		}
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin: 0 auto;
		h4 {
			line-height: 30px;
			@media (min-width: 778px){
				line-height: 40px;
			}
		}
		p {
			line-height: 23px;
			@media (min-width: 778px){
				font-size: 18px;
				line-height: 32px;
			}
			font-weight: 700;
		}
		.button {
			@media (min-width: 778px){
				font-size: 18px;
			}
			text-transform: uppercase;
		}
	}

	.col-main .std {
		display: flex;
		flex-direction: column;
		gap: 16px;
		@media (min-width: 778px){
			gap: 40px;
			.descr {
				padding: 40px;
			}
		}
	}

	.wrap-subcat {
		&::after {
			display: none;
			/* content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 20%;
			height: 100%;
			background: linear-gradient(to right,rgba(1,1,1,0), currentColor);
			pointer-events: none; */
		}
	
		.sub-categories ol {
			/* .count {
				display: none;
			} */
			li a {
				img {
					border: none;
					max-width: 118px;
					background: #f5f3ec;
					/* filter: drop-shadow(6px 4px 10px rgba(0,0,0,.2)); */
				}
				& > div {
					max-width: 118px;
					.category-name {
						font-size: 12px;
						line-height: 16px;
						@media (min-width: 778px){
							font-size: 14px;
							line-height: 23px;
						}
					}
				}
			}
		}	
	}
	.background-highlight2 {
		.wrap-subcat .sub-categories ol {
			li a {
				img {
					background: #fff;
				}
			}
		}
	}
}

/*Page > CMS*/

/*page 404*/

/* Customer */
.customer-account .col2-left-layout > .main {
	.col-main {
		order: 3;
	}

	.col-left {
		order: 2;
	}
}

/*! purgecss end ignore */