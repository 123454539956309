// /*SHOP*/

/* @media (min-width: 778px) {
	.category-image {
		position: relative;
		.wrap-subcat {
			position: absolute;
			bottom: 0;
			left: 20px;
		}
	}
} */

.wrap-subcat {
	/* @include right-blur($color: #fff);

	@media (min-width: 890px) {
		&:after {
			display: none;
		}
	} */


	h4 {
		display: none;
		@media (min-width: 778px) {
			display: inherit;		
			text-align: center;
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 20px;
		}
	}

	img {
		width: 100%;
		height: auto;
		border-radius: 100%;
		background: #fff;
	}

	li {
		text-align: center;
	}
	.sub-categories {
		h4 {
			display: none;
			@media (min-width: 778px) {
				display: inherit;
			}
		}
		&>nav {
			display: flex;
			flex-grow: 0;
			
		}
		
		
		ol {
			display: grid;
			padding-top: 2px;
			padding-right: 2px;
			&.explode {
				gap: 40px 16px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				> li {
					max-width: 100px;
				}
				> li:last-child:not(.view-all) {
					margin-right: 0;
				}
			}
			&:not(.noexplode) {
				gap: 10px 8px;
			}
			&.noexplode {
				gap: 10px 16px;
				li {
					padding: 0;
				}
			}
			grid-auto-flow: column;
			grid-auto-columns: max-content;
			overflow-x: auto;
			@include scrollbar-mini(transparent, transparent);
			scroll-snap-type: x mandatory;
			&:not(.noexplode) {
				@media (min-width: 778px) {
					gap: 40px 16px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
				}
			}

			&.slider-8col {
				&>li {
					@media (min-width: 778px) {
						max-width: 115px;
					}
				}
			}

			/* @media (min-width: 778px) {
				display: flex;
				flex-wrap: wrap;
			} */

			&>li {

				scroll-snap-align: start;

				max-width: 80px;
				@media (min-width: 778px) {
					max-width: 160px;
				}

				/* border: 2px solid #ddd; */
				padding: 4px 8px;
				/* border-radius: 8px; */
				/* transition: border-color 0.2s ease-in-out; */
				/* background: #fff; */

				&:hover {
					img {
						outline: 1px solid $color-brand;
					}
					& > a {
						color: $color-brand;
					}
				}
				a {
					color: $color-body;

					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 8px;
					@media (min-width: 778px) {
						gap: 10px;
					}
					
					text-align: center;
					line-height: 10px;

					.category-name {
						font-size: 12px;
						line-height: 16px;
						@media (min-width: 778px){
							font-size: 14px;
							line-height: 23px;
						}
						font-weight: 700;
						
					}
					.count {
						@extend .text-muted;
						font-size: 10px;
					}
				}


				&:last-child:not(.view-all) {
					margin-right: 24px;
				}

				&.view-all {
					//width: auto !important;
					background-color: #f5f3ec !important;
					//padding-right: 0;

					img {
						padding: 20px;
						@media (min-width: 778px) {
							padding: 39px;
						}
						width: 40px;
						height: 40px;
					}
				}
			}

		}
	}
	a.view-all {
		color: $color-brand;
		font-size: 12px;
		display: flex;
		justify-content: end;
		gap: 12px;
		font-weight: 700;
		&::after {
			content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none"><path d="M1 9L5 5L1 1" stroke="%23D92A64" stroke-width="2" stroke-linecap="round"/></svg>');
			width: 7px;
			height: 10px;
		}

	}
}
/*! purgecss start ignore */
.catalog-category-view .col2-left-layout {
	.products-grid {
		--grid-column-count: 3;
	}
}

.catalogsearch-result-index .col2-left-layout {
	.products-grid {
		--grid-column-count: 3;
	}
}
.searchindex-results {
	display: flex;
	gap: 8px;
	li {
		padding: 8px 12px;
		border: 0;
		border-radius: 22px;
		font-size: 14px;
		text-transform: uppercase;
		background: #f5e7e6;
		&.active {
			font-weight: 600;
	   	}	  
	}
	margin-bottom: 8px;
  }
.catalog-category-view .col1-layout {
	.products-grid {
		--grid-column-count: 5;
	}
}
/*! purgecss end ignore */
.catalog-category-view .col2-left-layout>.main {
	/* flex-direction: column-reverse; */
}

.category-title {
	@extend .mb-2;
	/* h1 {
		text-transform: uppercase;
	} */
}

/* .category-description {
	font-size: $font-xs;
	overflow: hidden;
  	height: 180px;
	@media (min-width: 778px) {
		font-size: $font-sm;
	}
} */

.category-products {
	@extend .mb-3;
}

.products-grid {
	display: flex;
	flex-direction: column;

	@media (min-width: 778px) {
		display: grid;
	}

	/*! purgecss start ignore */
	// &.products-grid--max-4-col {
	/**
	   * User input values.
	   */

	--grid-layout-gap: 16px;
	--grid-layout-gap-row: 24px;
	--grid-column-count: 2;
	--grid-item--min-width: calc(50vw - 32px);

	//--grid-item--min-width: calc(50vw);

	//margin: 0 -8px;

	@media (min-width: 420px) {
		--grid-item--min-width: 168px;
		margin: 0;
	}
	@media (min-width: 778px) {
		--grid-layout-gap: 24px;   /*  20px */
		--grid-layout-gap-row: 24px;
		//--grid-column-count: 6;
	}
	
	/**
	* Calculated values.
	*/
	--gap-count: calc(var(--grid-column-count) - 1);
	--total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
	--grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));
	
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
	row-gap: var(--grid-layout-gap-row);
	column-gap: var(--grid-layout-gap);
	// }
	/*! purgecss end ignore */


	.item {
		overflow: hidden;
		//background: #fbfbfb;

		--items-padding: 8px;

		@media (min-width: 778px) {
			--items-padding: 26px;
		}

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: calc(var(--items-padding) / 2);
		
		/* transition: border-color .15s ease-in-out;
		border-radius: 8px; */
		// border: 1px solid #f5f5f5;
		// background: #f5f5f5;
		border: 1px solid #eee;
		border-radius: 8px;
  		box-shadow: -2px 4px 12px rgba(85, 85, 85, 0.2);
		//background: #fff;
		// box-shadow: 3px 3px 8px rgba(0,0,0,.05);
		//overflow: hidden;
		
		.wrap-media {
			overflow: hidden;
			border-radius: unset;	
			position: relative;
			flex: 1 0 auto;
			

			.product-image {
				display: flex;
				/* width: 100%; */
				overflow: hidden;
				padding: 0;

				img {
					width: 100%;
					height: auto;
					overflow: hidden;
				}
			}

			.configurable-swatch-list {
				position: absolute;
				left: 16px;
				bottom: 8px;
				overflow: auto;
				scrollbar-width: none;
				width: calc(100% - 32px);
			}
		}

		.wrap-flex-sb {
			/* background: linear-gradient(0deg, rgb(244, 242, 242) 0%, rgba(221, 221, 221, 0) 100%); */
		}

		.product-info {
			padding: 0 var(--items-padding) 8px;

			.product-name {
				@extend .t-truncate;
				@extend .t-truncate-3;
				// height: 38px;
				/* font-size: 14px; */
				/* line-height: 16px; */
				font-size: 12px;
				@media (min-width: 778px) {
					font-size: 14px;
					line-height: 23px;
				}
				text-transform: capitalize;
				/* font-weight: 600; */
				& > * {
					color: #626262;
					font-weight: normal;
				}
			}

			.desc {
				font-size: 12px;
				line-height: 16px;

				/* @media (min-width: 778px) {
					font-size: 14px;
					line-height: 18px;
				} */

				@extend .t-truncate;
				@extend .t-truncate-2;
				margin-top: 4px;
				// margin-bottom: 8px;
				/* @media (min-width: 778px) {
					margin-top: 8px;
				} */
			}

			.ratings {
				.amount {
					display: none;
					@media (min-width: 778px) {
						display: initial;						
					}
				}
				// margin-bottom: 8px;
			}
		}

		.price-box {
			display: grid;
			/* grid-template-areas: 'oldprice price'
			'saveprice saveprice'
			'infotax infotax'; */
			grid-template-areas: 'startingfromlabel oldprice price saveprice'
			'startingfromlabel infotax infotax infotax';
			align-items: center;
			.configurable-price-from-label {
				
			}
			.old-price {
				grid-area: oldprice;
				order: 1;
				margin: 0;
				margin-right: 8px;
				@media (min-width: 778px) {
					margin-right: 12px;
				}
			}
			.minimal-price-link,
			.price-info-label {
				display: none;
			}
			.save-price,
			.discount-price-save  {
				grid-area: saveprice;
				order: 3;
				display: none;
				/* align-items: center;
				gap: 4px;
				margin-left: 8px;
				border-left: 1px solid #ddd;
				.price {
					margin-left: 8px;
				}
				.price-label {
					display: none;
				} */
				/* display: none; */
			}
			.price,
			.special-price,
			.regular-price,
			.minimal-price {
				grid-area: price;
				order: 2;
				margin: 0;
				.price {
					color: $color-brand;
				}
			}
			
			& > .label,
			.price-label {
				grid-area: startingfromlabel;
				margin-right: 4px;
  				font-size: 18px;
			}
			.info-tax {
				grid-area: infotax;
				line-height: 16px;
				font-size: 11px;
			}
			.old-price,
			.special-price {
				.price-label {
					display: none;
				}
			}

			//@extend .mb-1;
		}

		.actions {
			padding: 0 var(--items-padding) 8px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;

			.btn-cart,
			.button {
				align-self: center;
				width: 100%;
				display: none;
				@media (min-width: 778px) {
					display: inherit;
				}
			}

			.availability {
				text-align: center;
				line-height: 1.8;
				display: block;
				padding: 8px 0;
				margin-bottom: 0;
			}

			.add-to-links {
				display: none;
			}
		}

		.configurable_options {
		}

	}
}
/*.catalog-category-view {
	.col1-layout .category-products .products-grid {
		@media (min-width: 778px) {
			--grid-column-count: 6;
		}
	}
}*/

// disable button cart product grid
/* .products-grid .item .actions .availability,
.products-grid .item .btn-cart {
	display: none !important;
} */


.products-list {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.item {
		display: flex;
		flex-direction: column;
		@media (min-width: 778px) {
			flex-direction: row;
		}
		align-items: center;

		/* background: linear-gradient(0deg, rgb(244, 242, 242) 0%, rgba(221, 221, 221, 0) 100%); */
		border: 1px solid #f5f5f5;
		background: #fff;
		border-radius: 8px;
		overflow: hidden;
		.wrap-media {
			position: relative;
		}
		.product-image {
			display: flex;
			align-self: start;
			overflow: hidden;

			@media (min-width: 778px) {
				min-width: max-content;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.product-shop {
			flex-grow: 1;
			flex: 75%;

			@media (min-width: 778px) {
				flex: auto;
				align-self: start;
			}

			display: flex;
			flex-direction: column;
			padding: 16px;
			gap: 16px;
			@media (min-width: 778px) {
				gap: 32px;
				padding: 16px;
			}

			.product-primary {
				.product-name {
					@extend .t-truncate;
					@extend .t-truncate-md-3;

					font-size: 14px;

					@media (min-width: 778px) {
						font-size: 18px;
					}

					text-transform: capitalize;
					font-weight: 600;
					margin-bottom: 8px;
				}

				.ratings {
					.rating-links {
						display: none;
						font-size: 12px;

						@media (min-width: 778px) {
							font-size: 13px;
						}
					}
				}
			}

			.desc {
				display: none;

				@media (min-width: 778px) {
					font-size: 14px;
					line-height: 16px;
					display: block;
				}

				margin-top: 8px;
				@extend .t-truncate;
				@extend .t-truncate-md-2;

			}

			.product-secondary {
				display: flex;
				justify-content: start;
				flex-direction: column;
				@media (min-width: 778px) {
					flex-direction: row;
				}

				.price-box {
					display: flex;
					flex-direction: column;
					.old-price {
						order: 2;
					}
					.save-price {
						order: 3;
					}
					.price,
					.special-price {
						order: 1;
					}

					margin-bottom: 8px;

					&>* {
						display: flex;
						align-items: center;
						// margin: 0;
					}

				}

				.add-to-links {
					display: none;
				}
			}

		}

	}
}


/*! purgecss start ignore */

.block-slideshow {
	overflow: hidden;
	.block-content {
		max-width: var(--max-width-container);
		//@include right-blur($color: #fafafa, $width: 20%);
		position: relative;
	}
	ol {
		
	}
}
.block-banner-slideshow {
	position: relative;
	[x-ref="slider"] {
		display: grid;
		grid-template-columns: unset;
		grid-auto-flow: column;
		grid-auto-columns: 100%;
	
		@include scrollbar-mini(transparent, transparent);
		overflow-x: auto;
		scroll-snap-type: x mandatory;
	
		& > * {
			scroll-snap-align: center;
			transition: all 0.2s;
			display: grid;
			justify-content: center;
		}
		
		.item img {
			width: 100%;
		}
	}
	.prev,
	.next {
		/* &[aria-disabled="true"] {
			display: none;
		} */
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 999;
		cursor: pointer;
		/* background-color: rgba(255, 255, 255, 0.90);
		transition: background-color .2s ease-in-out;
		box-shadow: 0 1px 8px 0 rgba(0,0,0,.2);
		border-color: #928f8f;
		border-style: solid; */
		padding: 2px;
		display: flex;
		svg {
			width: auto;
			height: 40px;
			fill: $color-brand;
		}
	}
	.prev {
		//border-width: 1px 1px 1px 0;
		left: 0;
	}
	.next {
		//border-width: 1px 0 1px 1px;
		right: 0;
	}
	.slideshow__dots {

		@media (min-width: 778px) {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}

		--dots-gap: 10px;
		--dots-size: 10px;
		--dots-size-factor: 0.6;
		display: flex;			
		align-items: center;
		gap: var(--dots-gap);
		@include scrollbar-mini(transparent, transparent);
		overflow-x: auto;
		scroll-snap-type: x mandatory;
		scroll-snap-align: center;
		margin: 0 auto;
		padding: 20px 0;
		/* max-width: calc(
			var(--dots-size) * 3
			+ calc( calc(var(--dots-size) * var(--dots-size-factor)) * 3) 
			+ var(--dots-gap) * 5
		); */
		/** case with only 2 big dots */
		max-width: calc(
			calc( calc(var(--dots-size) * var(--dots-size-factor)) * 4) 
			+ var(--dots-gap) * 4
		);
		
		li {
			.dot {
				cursor: pointer;
				display: block;
				background: white;
				border: 1px solid $color-brand;
				border-radius: 100%;
				width: calc(var(--dots-size) * var(--dots-size-factor));
				height: calc(var(--dots-size) * var(--dots-size-factor));
				line-height: calc(var(--dots-size) * var(--dots-size-factor));
				transition: background-color .3s;
			}
			&.selected .dot {
				background-color: $color-brand;
				width: var(--dots-size);
				height: var(--dots-size);
				line-height: var(--dots-size);
			}
		}
		// before and after selected dots
		li.selected + li:nth-child(n+2) .dot,
		li:has(+li.selected) > .dot {
			background: white;
			width: var(--dots-size);
			height: var(--dots-size);
			line-height: var(--dots-size);
		}
	}
}
	

body .block-product-slider {
	.block-title {
		margin-bottom: 20px;
		@media (min-width: 778px) {
			margin-bottom: 40px;
			
		}
		display: block;
		h2 {
			@extend .font-heading;
			font-size: 32px;
			@media (min-width: 778px) {
				font-size: 36px;
			}
			font-weight: 100;
			text-align: center;
		}
	}
	.block-subtitle {
		font-size: 14px;
	}
}

.block-product-slider {
	/* overflow: hidden; */
	.block-content {
		max-width: var(--max-width-container);
		//@include right-blur($color: #fafafa, $width: 20%);
		position: relative;
		/* margin-right: -16px; */
		@media (min-width: 1025px) {
			margin-right: 0;
		}
		.products-grid:not(#widget-new-products) .item .product-name {
			font-size: 14px;
		}
		/* &:after {
			content: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.45496 9.96001L7.71496 6.70001C8.09996 6.31501 8.09996 5.68501 7.71496 5.30001L4.45496 2.04001' stroke='gainsboro' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 36px;
		} */

		.prev,
		.next {
			/* &[aria-disabled="true"] {
				display: none;
			} */
			position: absolute;
			top: calc(50% - 15px);
			/*top: 50%;*/
			transform: translateY(-50%);
			z-index: 999;
			cursor: pointer;
			/* background-color: rgba(255, 255, 255, 0.90);
			transition: background-color .2s ease-in-out;
  			box-shadow: 0 1px 8px 0 rgba(0,0,0,.2);
			border-color: #928f8f;
			border-style: solid; */
			padding: 2px;
			display: flex;
			background-color: $color-brand;
			border-radius: 50%;
			svg {
				width: auto;
				height: 26px;
				fill: #fff;
				/* fill: $color-brand; */
			}
		}
		.prev {
			//border-width: 1px 1px 1px 0;
			left: -13px;
		}
		.next {
			//border-width: 1px 0 1px 1px;
			right: -13px;
		}
	}
}

/* html {
	.block-content {
		.prev,
		.next {
			background-color: rgba(255, 255, 255, 0.90);
			transition: background-color .2s ease-in-out;
			&:hover {
				background-color: rgba(255, 255, 255, 1);
			}
		}
	}
} */
//@media (hover: hover) and (pointer: fine) {
	html {
		// animation zoom only no-touch	
		.products-list,
		.products-grid {
			.item:not(.out-of-stock) {
				.product-image img {
					//transition: transform .3s cubic-bezier(.68, -0.55, .27, 1.55);
				}
				//.actions {
					@media (min-width: 778px) {
						.btn-cart,
						.button {
							visibility: hidden;
						}
						/* &:hover {
							justify-content: end;
							.price-box {
								display: none;
							}
							.btn-cart,
							.button {
								visibility: visible;
								flex: 1;
								span {
									display: inherit;
								}
							}
						} */
					}
				//}
				&:hover {
					.product-image img {
						//transform: scale(1.2);
					}
				}
			}
		}

		// highlight product on hover lowlight out-of-stock
		.products-list,
		.products-grid {
			.item {
				&:not(.out-of-stock) {
					&:hover {
						// box-shadow: $box-shadown-2-hover;
						border-color: $color-brand;
					}
					a:hover {
						color: $color-brand;
					}
				}
			}
		}
	}
//}

// out-of-stock lowlight
.products-list,
.products-grid {
	.item {
		&.out-of-stock {
			opacity: 0.5;
			.btn-cart {
				display: none !important;
			}
		}
	}
}

.widget-products {
	& > .view-all {
		color: $color-brand;
		font-size: 12px;
		display: flex;
		justify-content: end;
		gap: 12px;
		font-weight: 700;
		/* margin-top: 20px;
		margin-right: 16px; */
		&::after {
			content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none"><path d="M1 9L5 5L1 1" stroke="%23D92A64" stroke-width="2" stroke-linecap="round"/></svg>');
			width: 7px;
			height: 10px;
		}

	}
}

/*! purgecss end ignore */

/* Products Related */
.block-product-slider .products-grid {

	/* padding-right: 20%; */
	padding-right: 16px;
	@media (min-width: 1024px)  {
		padding-right: 0;
	}

	--grid-column-count: 5;
	--grid-item--min-width: 180px;
	//--grid-item--max-width: calc( 45vw - 16px);
	--grid-item--max-width: 180px;
	/* @media (min-width: 778px) {
		--grid-item--max-width: 160px;
	} */
	&#widget-slide-products {
		--grid-item--min-width: 80px;
		--grid-item--max-width: 80px;	
		--grid-layout-gap: 8px;
	}
	/* display: grid;
	grid-template-columns: unset; */
	grid-auto-flow: column;
	grid-auto-columns: 180px;

	overflow-x: auto;
	scroll-snap-type: x mandatory;

	& > * {
		scroll-snap-align: start;
		transition: all 0.2s;
		background: #fff;
		box-shadow: unset;
		
		&.view-all {
			& > a {		
				height: 100%;
				background: #DF94AD;
				border-radius: 8px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 18px;

				font-size: 18px;
				line-height: 32px;
				text-align: center;
				font-weight: 700;
				&::after {
					content: url('data:image/svg+xml,<svg width="11" height="18" viewBox="0 0 14 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.66663 1.49609L12.6666 10.4961L1.66663 19.4961" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
					display: grid;
					align-items: center;
					justify-items: center;
					background: $color-brand;
					border-radius: 50%;
					width: 40px;
					height: 40px;			
				}
			}
		}
	}
	.item .price-box .save-price {
		display: none;
	}
	.item {
		.price-box {
			.special-price,
			.regular-price,
			.minimal-price,
			.full-product-price{
				.price {
					font-size: 14px;
				}
			}
		}
		.product-info,
		.actions {
			padding: 8px calc(var(--items-padding) / 2) 8px;
		}
		.product-name {
			line-height: 16px;
		}
	}

	@include scrollbar-mini(transparent, transparent);
}

.price-box {
	.old-price {
		line-height: initial;
		font-size: 12px;
		@media (min-width: 778px) {
			font-size: 14px;
		}
		color: #626262;
		.price {
			text-decoration: line-through;
		}
	}

	.save-price {
		margin: 0;
		line-height: initial;
		font-size: 12px;

		@media (min-width: 778px) {
			font-size: 14px;
		}

		color: #B12704;

		// alternative 2
		// font-size: 12px;
		// color: #000;
		// background: #7fda69;
		// padding: 0 4px;
		.price {
			// text-decoration: line-through;
		}
	}

	.special-price,
	.regular-price,
	.minimal-price,
	.full-product-price {
		/* margin-top: 8px; */
		font-size: 14px;

		@media (min-width: 778px) {
			font-size: 16px;
			line-height: normal;
		}


		/*! purgecss start ignore */
		.price-label span {
			color: $color-brand-2;
		}

		/*! purgecss end ignore */
		.price {
			
			color: $color-price;
			font-weight: 700;
			font-size: 14px;

			@media (min-width: 778px) {
				font-size: 18px;
			}
		}
	}
}

.ratings {
	display: flex;
	align-items: center;
	gap: 4px;
	margin-bottom: 0;
	--star-rating__icon-active: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23d92a64"><path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" /></svg>');
	--star-rating__icon-inactive: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23eee"><path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clip-rule="evenodd" /></svg>');;

	.rating,
	.rating-box {
		height: 16px;
		background-repeat: repeat-x;
	}

	.rating-box {
		width: 80px;
		background-image: var(--star-rating__icon-inactive);
		background-size: 16px;
	}

	.rating {
		background-image: var(--star-rating__icon-active);
		background-size: 16px;
  		background-position: left;
	}

	.amount {
		font-size: 14px;
	}
}

.no-rating {
	font-size: 14px;
	margin: 0;
}

.product_view__infosolidale {
	display: flex;
	gap: 20px;
	padding: 12px 20px;
	background: #F5F3EC;
	border-radius: 8px;
	margin-bottom: 16px;
	a {
		text-decoration: underline;
		color: $color-brand;
		font-weight: 700;
	}
	> span {
		flex-basis: 100%;
		line-height: normal;
		font-size: 12px;
		@media (min-width: 778px) {
			font-size: 14px;
		}
	}
}
.product-view {

	.product-name {
		margin-bottom: 20px;
		h1 {
			text-transform: capitalize;
			font-size: 14px;
			font-weight: 700;
			line-height: 23px;
			@media (min-width: 778px) {
				font-size: 26px;
				font-weight: normal;
			}
		}
	}

	.product-essential {
		.col2-set {
			flex-direction: column;
			gap: 24px;
			@media (min-width: 1280px) {
				gap: 32px;
				/* max-width: 650px; */
				max-width: 893px;
				/* flex-direction: row; */
			}

			.col-1,
			.col-2 {
				width: 100%;
				/* max-width: 650px; */
			}

			@media (min-width: 1280px) {
				.col-1 {
					width: 100%;
					max-width: 100%;
				}

				.col-2 {
					/* width: 60%; */
					/* min-width: fit-content !important; */
					min-width: 380px;
				}

			}
		}

		@media (min-width: 960px) {
			form {
				display: contents;
			}

			display: grid;
			grid-template-areas: 'imgbox shop'
			'imgbox addtocart'
			'imgbox collateral';
			//grid-template-columns: min(750px, 50%) 47%;
			grid-template-columns: min(500px,45%) 1fr;
			justify-content: space-between;

			gap: 0 4%;


			.product-img-box {
				grid-area: imgbox;
			}

			.product-shop {
				grid-area: shop;
			}

			.add-to-cart-wrapper {
				grid-area: addtocart;
			}

			.product-collateral {
				grid-area: collateral;
			}

			.col2-set {
				position: sticky;
				top: 10px;
				align-self: start;
				height: fit-content;
			}

		}

		.product-img-box {
			/* margin-left: -16px;
			margin-right: -16px; */
			height: fit-content;
			margin-bottom: 16px;
			@media (min-width: 778px) {
				margin: 0;
				position: sticky;
				top: 10px;
			}
		}

		.product-img-box .product-image {
			position: relative;
			width: 100%;

			@media (min-width: 778px) {
				// box-shadow: $box-shadown-1;
				//border: 1px solid #eee;
				//border-radius: $border-radius-2;
			}

			overflow: hidden;

			.product-image-gallery {
				border: 1px solid #eee;
				border-radius: 8px;
				overflow: hidden;
				@media (min-width: 778px) {
					/* height: 500px; */
				}
				.zoom {
					position: absolute;
					top: 10px;
					right: 10px;
					z-index: 999;
					cursor: pointer;
					svg {
						color: $color-brand;
					}
				}
				.prev,
				.next {
					/* &[aria-disabled="true"] {
						display: none;
					} */
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					z-index: 999;
					cursor: pointer;
					/* background-color: rgba(255, 255, 255, 0.90);
					transition: background-color .2s ease-in-out;
					box-shadow: 0 1px 8px 0 rgba(0,0,0,.2);
					border-color: #928f8f;
					border-style: solid; */
					padding: 2px;
					display: flex;
					svg {
						color: $color-brand;
						width: auto;
						height: 44px;
						@media (min-width: 778px) {
							height: 80px;
						}
					}
				}
				.prev {
					//border-width: 1px 1px 1px 0;
					left: 0;
				}
				.next {
					//border-width: 1px 0 1px 1px;
					right: 0;
				}

				/* display: flex; */
			}
		}
	/* 
		.product-img-box .product-name {
			display: none;
		} */

		.product-img-box .product-image img {
			/* aspect-ratio: 1/1; */
			width: 100%;
			max-width: 100%;
			height: 100%;
			margin: 0px auto;
		}

		.more-views {
			margin: 0;	
			h2 {
				display: none;
			}
			.prev,
			.next {
				top: 50%;
			}
			.product-image-dots {
				--dots-gap: 10px;
				--dots-size: 10px;
				--dots-size-factor: 0.6;
				display: flex;			
				align-items: center;
				gap: var(--dots-gap);
				@include scrollbar-mini(transparent, transparent);
				overflow-x: auto;
				scroll-snap-type: x mandatory;
				scroll-snap-align: center;
				margin: 0 auto;
				padding: 20px 0;
				/* max-width: calc(
					var(--dots-size) * 3
					+ calc( calc(var(--dots-size) * var(--dots-size-factor)) * 3) 
					+ var(--dots-gap) * 5
				); */
				/** case with only 2 big dots */
				max-width: calc(
					var(--dots-size) * 3
					+ calc( calc(var(--dots-size) * var(--dots-size-factor)) * 4) 
					+ var(--dots-gap) * 4
				);
				li {
					.dot {
						cursor: pointer;
						display: block;
						background: white;
						border: 1px solid $color-brand;
						border-radius: 100%;
						width: calc(var(--dots-size) * var(--dots-size-factor));
						height: calc(var(--dots-size) * var(--dots-size-factor));
						transition: background-color .3s;
					}
					&.selected .dot {
						background-color: $color-brand;
						width: var(--dots-size);
						height: var(--dots-size);
					}
				}
				// before and after selected dots
				li.selected + li:nth-child(n+2) .dot,
				li:has(+li.selected) > .dot {
					background: white;
					width: var(--dots-size);
					height: var(--dots-size);
				}
			}
			.product-image-thumbs {
				display: flex;
				gap: 8px;
				margin-left: 8px;
				overflow-x: auto;
				scroll-snap-type: x mandatory;
				@include scrollbar-mini(transparent, transparent);
	
				@media (min-width: 778px) {
					margin-left: 0;
				}
	
				&>li {
					flex-shrink: 0;
					border: 1px solid transparent;
					transition: all .3s;
					&:hover,
					&.selected {
						border-color: $color-brand;
					}
					border-radius: $border-radius-2;
					overflow: hidden;
	
					a {
						user-select: none;
					}
	
					img {
						aspect-ratio: 1/1;
						width: 100%;
						display: block;
					}
				}
			}
		}

		.product-image-gallery .gallery-image {
			display: none;
		}

		.product-image-gallery .gallery-image.visible {
			display: block;
		}

		/* .product-image-thumbs li:first-child {
			margin-left: -1px;
		} */

		.product-image-thumbs li {
			display: inline-block;
		}

		.product-name .h1 {
			font-weight: 900;
			text-transform: capitalize;
		}

		.product-shop {
			.availability {
				display: inline-block;
				margin-bottom: 8px;

				padding: 4px 16px;
				font-weight: bold;

				&.in-stock {
					background: #def9f4;
					color: #3bb7a6;
				}

				&.out-of-stock {
					color: #f74b81;
					background: #fde0e9;
				}
			}

			.amshopby-mode-view {
				display: none;
				@media (min-width: 778px) {
					display: inherit;
					flex-shrink: 0;
				}
				img {
					max-width: 120px;
				}
			}

			/* .product-name h1 {
				text-transform: uppercase;
			} */
		}

		
	}
}

.sidebar-active .col2-set {
	position: initial !important;
}
.product-additional {
	margin-top: 80px;
}

.product-view .product-collateral {
	@extend .mt-5;
	@extend .mb-5;
}

.full-highlight {
	background: #f5f3ec;

	/* // debug
	outline: 1px solid blue;
	background: red; */

	padding-block: 20px;
	@media (min-width: 778px) {
		padding-block: 40px;
	}
	display: grid;
	justify-content: center;
	margin-left: -20px;
	//width: calc(100vw - 40px);
	width: calc(100% + 20px);
	padding-left: 20px;
  	margin-right: -20px;
	@media (min-width: 1220px) {
		padding-right: 0;
		padding-left: 0;
		margin-left: calc(
			calc(
				calc(100vw - var(--max-width-container))
				/ 2
				)
				* -1);
		width: calc(100vw - 8px);
		//width: 100vw;		
		& > * {
			max-width: var(--max-width-container);
			//min-width: var(--max-width-container);
		}
	}
	
	&:has(.full-highlight__content) {
		padding-right: 20px;
		width: 100%;
		@media (min-width: 1220px) {
			padding-right: 0;
			width: calc(100vw - 8px);
		}
	}
	.block-product-slider {
		overflow: hidden;
		.block-content {
			margin-right: 0;
			.prev {
				left: 15px;
			}
			.next {
				right: 15px;				
			}
		}
	}
}

.catalog-product-view .product-essential {
	.extra-info {
		margin-top: 6px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 4px;
		/* flex-direction: column; */

		@media (min-width: 778px) {
			gap: 16px;
			justify-content: flex-start;
			flex-direction: row;
		}
		a {
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.price-box {
		display: grid;
		grid-template-areas: 
			'infolabel infolabel'
			'saveprice price'
			'oldprice oldprice';	
		align-items: center;
		gap: 10px 6px;
		max-width: fit-content;
		.old-price {
			grid-area: oldprice;
			order: 1;
			margin-right: 4px;			
			font-size: 14px;
			.price {
				font-size: 14px;
			}
		}
		/* .minimal-price-link, */
		.save-price {
			grid-area: saveprice;
			order: 3;
			
			font-size: 28px;
			font-weight: 400;
			color: $color-brand;
			.price {
				font-family: 'Georgia', 'Verdana';
				font-size: 28px;
			}
		}
		.price,
		.special-price,
		.full-product-price {
			grid-area: price;
			order: 2;
			margin: 0;
			
			font-size: 36px;
			.price {
				order: 0;
				font-size: 36px;
			}
		}
		.minimal-price-link {
			display: none;
		}
		&>* {
			/* display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px; */
		}

		.special-price .price-label {
			display: none;

		}
		.price-as-configured {
			display: flex;
			flex-direction: column;
			gap: 4px;
			.price-label {
				display: inherit;
			}
		}
		.price-info-label {
			grid-area: infolabel;
			line-height: normal;
			background: $color-brand;
			color: white;
			padding: 4px 16px;
			font-size: 12px;
			text-transform: uppercase;
			border-radius: 4px;
			width: fit-content;
		}

		//@extend .mb-1;
	}


	/* .price-box {
		
		.price-label {
			display: none;
		}
		display: grid;
		grid-template-columns: 1fr 1fr;
		max-width: fit-content;
		gap: 4px 8px;
		align-items: center;

		// margin-bottom: 8px;
		&>* {
			//display: flex;
			align-items: center;
			//justify-content: space-between; 
			gap: 8px;
		}



		.old-price {
			order: 1;
			font-size: 14px;
		}

		.save-price {
			grid-column: span 2;
			order: 2;
			font-size: 16px;
			display: flex;
			.price-label {
				display: block;
			}
		}

		.special-price {
			order: 0;
			font-size: 18px;

			.price {
				order: 0;
				font-size: 24px;
			}
		}
	} */
	.product-options-bottom {
		@media (min-width: 778px) {
			padding: 20px;
			border: 1px solid #eee;
			border-radius: 8px;
		}
		.product-options-bottom-extra {
			font-size: 12px;
			border-top: 1px solid #eee;
			padding-top: 20px;
			margin: 20px 0;
		}
	}
	.add-to-cart {
		display: flex;
		align-items: end;
		gap: 8px;
		@media (min-width: 778px) {	
			/* display: grid;
			grid-template-columns: minmax(120px, max-content) 1fr; */
			gap: 16px;
		}

		.add-to-cart-buttons {
			flex-grow: 1;

			.btn,
			button {
				width: 100%;
			}

			.availability {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;
				font-size: $font-xs;

				.label {
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					text-indent: -9999px;

					&::after {
						line-height: 0;
						/* Collapse the original line */
						text-indent: 0;
						// display: block;
						// content: '';
						height: 16px;
						width: 16px;
						line-height: initial;
						/* New content takes up original line height */
						font-size: 18px;
						font-weight: bold;
						fill: $color-body;
						stroke: $color-body;
						background-repeat: no-repeat;
						background-position: center;
					}
				}

				&.in-stock .label::after {
					content: url('data:image/svg+xml,\
					<svg fill="%2311b400" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>checkmark</title> <path d="M16 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zM23.258 12.307l-9.486 9.485c-0.238 0.237-0.623 0.237-0.861 0l-0.191-0.191-0.001 0.001-5.219-5.256c-0.238-0.238-0.238-0.624 0-0.862l1.294-1.293c0.238-0.238 0.624-0.238 0.862 0l3.689 3.716 7.756-7.756c0.238-0.238 0.624-0.238 0.862 0l1.294 1.294c0.239 0.237 0.239 0.623 0.001 0.862z"></path> </g></svg>');
				}

				&.out-of-stock .label::after {
					content: url('data:image/svg+xml,\
					<svg fill="red" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g> <path d="M48,0A48,48,0,1,0,96,48,48.0512,48.0512,0,0,0,48,0Zm0,84A36,36,0,1,1,84,48,36.0393,36.0393,0,0,1,48,84Z"></path> <path d="M64.2422,31.7578a5.9979,5.9979,0,0,0-8.4844,0L48,39.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844L39.5156,48l-7.7578,7.7578a5.9994,5.9994,0,1,0,8.4844,8.4844L48,56.4844l7.7578,7.7578a5.9994,5.9994,0,0,0,8.4844-8.4844L56.4844,48l7.7578-7.7578A5.9979,5.9979,0,0,0,64.2422,31.7578Z"></path> </g> </g></svg>');
				}
			}
		}
	}

	.short-description {
		/* margin-top: 16px; */
		font-size: 14px;
	}

	.add-to-cart-wrapper {
		// margin: 12px 0;
		// padding: 8px;
		//@extend .p-3;
		//@extend .mb-5;

		/* @media (min-width: 1336px) {
			padding: 32px;
		} */

		/* @media (min-width: 778px) { */
		/* border: 1px solid #ececec;
		border-radius: 15px;
		box-shadow: 1px 5px 5px rgba(0, 0, 0, .2); */
		//background: linear-gradient(0deg, #f4f2f2 0, rgba(221, 221, 221, 0) 100%);
		//background: linear-gradient(-180deg, rgb(244, 242, 242) 0%, rgba(221, 221, 221, 0) 100%);
		/* background: linear-gradient(0deg, rgb(244, 242, 242) 0%, rgba(221, 221, 221, 0) 100%); */
		/* background: #fff; */

		/* } */
		@media (min-width: 778px) {
			// max-width: 50%;
		}

		display: flex;
		flex-direction: column;
		gap: 16px;

		.price-info {
			//@extend .mb-1;
		}

		.price-box,
		.product-options-bottom {

			/* padding-bottom: 16px;
			border-bottom: 1px solid #eee; */
			.qty-wrapper {
				flex-direction: column;
				gap: 2px;
				label {
					font-size: 12px;
					color: #000;
					font-weight: normal;
				}

				.qty {
					max-width: 36px;
					aspect-ratio: 1;
					line-height: 50px;
					height: 32px;
					padding: 0 10px;
					&[type="number"] {
						max-width: initial;
						width: 60px;
					}
				}
			}
		}
		.product-options-bottom:not(.sticky) {
			/* .add-to-box {
				padding-bottom: 20px;
				border-bottom: 1px solid #eee;
			} */
			/* padding-bottom: 20px; */
		}

		.product-options {
			select,
			input[type="text"],
			textarea {
				width: 100%;
				/* &:not(:first-child) {
					margin-top: 8px;
				} */
			}
			select {
				max-width: 175px;
			}
			.validation-advice {
				margin-top: 0;
			}
			.options-list > li:has(input[type="radio"], input[type="checkbox"]) {
				display: flex;
				gap: 8px;
			}
		}

		.availability {
			text-align: right;
			font-size: 14px;
			.label {
				font-weight: 700;
			}
		}
	}
	
	.availability-only {
		@extend .mb-2;
		text-decoration: underline;
		strong {
			font-weight: bold;
			color: #B12704;
		}
	}
}

.product-extra-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: end;
}
.product-attributes {
	/* @extend .text-muted; */
	font-size: 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;	
	li {
		display: flex;
		justify-content: end;
		gap: 4px;
		text-align: right;
		.label {
		}
		.data {
			font-weight: 700;
		}
	}
}
.products-grid .product-attributes {
	//font-size: 10px;
}

/*! purgecss start ignore */
.product-options-bottom {

	&.sticky {
		position: fixed;
		bottom: 0;
		right: 0;
		max-width: 650px;
		min-width: calc(360px - 15px);
		width: calc(100% - 16px);

		padding: 8px !important;
		border-top: 1px solid #eee;
		box-shadow: 0 4px 10px 0 #00000029;
		background: linear-gradient(0deg, #eee 0, rgb(255, 255, 255) 100%);

		z-index: 9999999999;

		@media (min-width: 778px) {
			position: relative;
			width: auto;
			max-width: none;
			box-shadow: none;
			border: none;
			background: transparent;
		}
	}
}

/*! purgecss end ignore */
.add-to-links {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: end;
	gap: 16px;
	font-size: 14px;
	& > * {
		display: inline-flex;
	}

	.link-wishlist {
		display: inline-flex;
		align-items: center;
		gap: 2px;
		span {
			display: none;
		}
		&:before {
			content: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.9918 10.6381C17.5926 7.50785 13.5917 6.66583 10.5856 9.53218C7.57957 12.3985 7.15636 17.1909 9.51704 20.5809C10.9208 22.5968 14.3591 26.2118 16.9158 28.8112C17.9712 29.8842 18.4989 30.4207 19.1341 30.6382C19.6775 30.8244 20.306 30.8244 20.8495 30.6382C21.4847 30.4207 22.0124 29.8842 23.0678 28.8112C25.6245 26.2118 29.0627 22.5968 30.4665 20.5809C32.8272 17.1909 32.4557 12.3684 29.3979 9.53218C26.3402 6.69598 22.391 7.50785 19.9918 10.6381Z' stroke='%23333333' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			width: 40px;
			height: 40px;
		}
		&:hover {
			color: #ff0083;
		}
	}
}


.link-learn {
	color: $color-body;
	font-size: 14px;
	font-weight: bold;
	text-decoration: underline;
	display: inline-flex;
	align-items: center;
	gap: 2px;
	&:after {
		content: url('data:image/svg+xml,\
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd"></path></svg>');
		width: 20px;
		height: 20px;
	}
}

.product-description {
	/* background-color: #eee;
	border-radius: 20px;
	padding: 40px; */
	h2 {
		margin-bottom: 8px;
	}
}

#product-review.skip-content {
	max-width: min(450px,75%);
}




.wrap-flex-sb {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.count-container {
	display: flex;
	// flex-direction: row-reverse;
	// flex-grow: 1;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	.amount {
		margin: 0;
		font-size: 12px;
		line-height: normal;
		@media (min-width: 778px) {
			font-size: 14px;
		}
		& > span {
			font-weight: 700;
		}
	}
}


.toolbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	@media (min-width: 778px) {
		flex-direction: row;
	}

	margin: 0 auto 20px auto;
	gap: 8px;
	// & > * {
	// 	display: flex;
	// }
}

.sorter,
.pager {
	display: flex;
	.next-fastforward {
		display: none;
	}
	@media (min-width: 778px) {
		justify-content: space-between;
		align-items: center;
	}

	gap: 8px;

	color: $color-body;

	&>* {
		display: flex;
		align-items: center;
		gap: 8px;
		margin: 0;

		font-size: 12px;
	}
}

.pager {
	flex-direction: column;

	@media (min-width: 778px) {
		flex-direction: row;
	}
}

.view-mode {
	display: none;
}
/* .view-mode, */
.sort-by,
.limiter,
.pages ol {
	display: flex;
	align-items: center;
	gap: 8px;
	/* border: 1px solid #eee;
	border-radius: 4px; */
	/* border: 1px solid #eee;
	background: #fff; */
	padding: 4px auto;
	// box-shadow: 0 1px 2px 0 rgba(0,0,0,.1);
	font-size: 12px;
	@media (min-width: 778px) {
		font-size: 14px;
	}
}
/*
select,
.view-mode strong,
.view-mode a {
	// margin-left: 8px;
	font-size: 12px;
	// border: 1px solid #ccc;
	// border-radius: $border-radius-2;
	// background: #fff;

}*/

/* .view-mode strong {
	color: $color-brand;
} */

label {
	// display: none;
	// @media (min-width: 778px) {
	// 	display: block;
	// }
	margin: 0;
	font-weight: 500;
	color: #000;
}

select,
p {
	font-size: inherit;
}

.sort-by {
	select {
		background: #F5E7E6;
		option {
			background: initial;
			&:checked {
				background: #F5E7E6;
			}
		}
		border: 0;
		border-radius: 22px;
		text-transform: uppercase;
		padding: 4px 8px !important;
		font-size: 12px;
		@media (min-width: 778px) {
			font-size: 14px;
			padding: 8px 12px !important;
		}
	}
	.sort-by-switcher {
		text-indent: -9999px;
		line-height: 0;

		/* Collapse the original line */
		&:after {
			text-indent: 0;
			display: block;
			line-height: initial;
			/* New content takes up original line height */
		}
		&:hover {
			text-decoration: none;
		}
	}

	.sort-by-switcher--asc:after {
		//content: '↑';
		content: url('data:image/svg+xml,<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10.1667L5.16667 14.3333M5.16667 14.3333L9.33333 10.1667M5.16667 14.3333V1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
		rotate: 180deg;
	}

	.sort-by-switcher--desc:after {
		//content: '↓';
		content: url('data:image/svg+xml,<svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10.1667L5.16667 14.3333M5.16667 14.3333L9.33333 10.1667M5.16667 14.3333V1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>');
	}
}

/* .view-mode-limiter {
	display: none;

	@media (min-width: 778px) {
		display: inherit;
	}

	// flex-direction: row-reverse;
} */


.category-products {
	.toolbar + .pager {
		display: none;
	}
	.toolbar-bottom .pager {
		display: flex;
	}
	.pages {
		display: none;
		&>strong {
			display: none;
			@media (min-width: 778px) {
				display: inherit;			
			}
		}
	}
}

.pages {
	/* display: none;
	
	&>strong {
		display: none;
		@media (min-width: 778px) {
			display: inherit;			
		}
	} */
	
	ol {
		/* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .1); */
		padding: 16px 0;
		
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;

		&>li>a,
		&>li:not(:has(a)) {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			color: $color-brand;
			border: 1px solid $color-brand;
			font-size: 16px;
			font-weight: 700;
			width: 40px;
			height: 40px;
			border-radius: 100%;
			
		}

		.current,
		&>li>a:hover {
			// border-color: $color-brand;
			background: $color-brand;
			font-weight: bold;
			color: white;
			fill: white;
			stroke: white;
		}

		.next,
		.previous {
			text-indent: -9999px;
			line-height: 0;

			/* Collapse the original line */
			&:after {
				text-indent: 0;
				// display: block;
				// content: '';
				height: 40px;
				width: 40px;
				line-height: initial;
				/* New content takes up original line height */
				font-size: 18px;
				font-weight: bold;
				fill: $color-brand;
				stroke: $color-brand;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		.next:after,
		.previous:after {
			content: url('data:image/svg+xml,\
			<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">\
<path d="M17 13L24 20L17 27" stroke="%23d92a64" stroke-width="2.5" stroke-linecap="round"/>\
</svg>');		
		}

		.next:hover::after,
		.previous:hover::after {
			content: url('data:image/svg+xml,\
			<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">\
<path d="M17 13L24 20L17 27" stroke="white" stroke-width="2.5" stroke-linecap="round"/>\
</svg>');			
		}

		.previous:after {
			transform: rotate(180deg);
		}

	}
}

.toolbar-bottom {
	.pager {
		gap: 16px;
		justify-content: space-between;
		@extend .mt-4;

		/* .count-container {
			display: none;
		} */
		.skip-links {
			display: none;
		}

		.next-fastforward {
			display: flex;
			justify-content: center;

			@extend .btn;
			@extend .btn-ui2;
			@extend .btn-md;
			border-radius: 4px;
			padding: 10px 20px !important;
  			font-size: 14px;
			background: $color-brand !important;
		}

		.pages {
			display: flex;
			justify-content: center;			
		}
	}

	.toolbar {
		display: none;
	}
}

/* .crosssell {
	@media (min-width: 778px) {
		max-width: 1400px;
	}
} */

.wrap-cart {
	.cart h6 {
		@extend .mb-3;
	}
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	
	@media (min-width: 968px) {
		flex-direction: row;
		justify-content: space-between;
		max-width: 1400px;
		margin: 0 auto;
		gap: 64px;
	}

	/* margin: 32px auto; */

	aside {
		margin: 0 -20px;
		@media (min-width: 968px) {
			margin: 0;
		}
		.page-title {
			display: none;
			@extend .mb-3;
			@media (min-width: 968px) {
				display: block;
			}
		}

		min-width: calc(360px - (16px * 2));

		@media (min-width: 968px) {
			flex: 0;
			align-self: start;
			position: sticky;
			top: 20px;
			min-width: 420px;
		}

		// position: sticky;
		// top: 10px;
		.theiaStickySidebar {
			// transform: none !important;
		}
	}

	&>.cart {
		@media (min-width: 778px) {
			flex: 1;
		}
	}

}

.cart-table {

	margin-bottom: 40px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.item-cart {
		position: relative;
		display: grid;
		.product-cart-image {
			grid-area: image;
		}
		.product-cart-info {
			grid-area: info;
		}
		.product-cart-actions {
			grid-area: actions;
		}
		grid-template-areas:
			'image info '
			'actions actions';
		@media (min-width: 778px) {
			grid-template-areas:
			'image info'
			'image actions';
			grid-template-columns: auto 1fr;
		}
		gap: 8px;

		
		padding: 12px;
		border-radius: 8px;
		border: 1px solid #DADADA;
		box-shadow: -2px 4px 12px 0px rgba(85, 85, 85, 0.20);
	}
	.product-cart-image {
		max-width: 100px;
		@media (min-width: 1215px) {
			max-width: 140px;
		}
	}
	.product-cart-remove {
		position: absolute;
		top: -8px;
		right: -8px;
		a {
			display: grid;
			place-items: center;
			
			line-height: normal;
			background-color: $color-brand;
			color: white;
			border-radius: 50%;
			width: 24px;
			height: 24px;
			text-align: center;
		}
	}

	.product-cart-info {
		flex: 1;

		display: flex;
		flex-direction: column;

		.product-name {
			font-size: 14px
		}

		.product-cart-sku {
			@extend .text-muted;
			@extend .font-xs;

			.label {
				font-weight: 500;
			}
		}

		.item-options {
			dt {
				font-weight: 500;
			}

			dt,
			dd {
				@extend .text-muted;
				@extend .font-xs;
			}
		}

		.cart-price-unit {
			display: flex;
			align-items: center;
			gap: 4px;

			&__qty {
				@extend .text-muted;
				@extend .font-xs;
			}

			&__price .price {
				@extend .font-sm;
			}
		}
	}

	.product-cart-actions {
		display: flex;
		justify-content: space-between;
		& > * {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			&.product-cart-actions__qty {
				align-items: center;
			}
			gap: 4px;
			.label {
				color: #333;
				line-height: normal;
			}
			.price {
				font-size: 14px;
			}
		}
	}

	.qty-wrapper {
		display: inherit;
		position: relative;
		.quantity-button {
			position: absolute;
			right: -90px;
			@extend .btn;
			@extend .btn-xs;
			@extend .btn-ui2;
		}
		.cart-item-quantity {
			padding: 4px 8px;
			aspect-ratio: unset;
			max-width: unset;
			border-radius: 4px;
		}
		.cart-item-quantity:focus+.quantity-button {}
	}

}

.cart-totals>table {
	margin: 0;
	// tbody {
	// 	display: flex;
	// 	flex-direction: column;
	// 	gap: 8px;
	// 	tr {
	// 		width: 100%;
	// 		display: flex;
	// 		justify-content: space-between;
	// 		align-items: center;
	// 		td:first-child {
	// 			flex: 0 0 100%;
	// 		}
	// 	}
	// }
	td {
		border: none;
		color: $color-body;
		font-size: $font-md;

		// padding: 0;
		&:first-child {
			text-align: left;
		}
	}

	tbody tr {
		

		&:first-child td {
			/* padding-top: 0; */
		}

		td {
			line-height: 16px;
			padding: 4px 4px;
			font-size: 12px;
			@media (min-width: 768px) {
				font-size: 14px;
			}
			.price {
				font-size: 18px;
			}
		}
	}

	tfoot td {
		padding: 20px 4px;
		padding-bottom: 0;
		font-size: 14px;
		.price {
			font-size: 24px;
		}
	}
}


.cart-forms {
	/* margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #eee; */
	text-align: center;
}

.minicart-message {
	display: none;
	position: relative;
	padding: 8px 16px;
	margin-top: 8px;
	border: 1px solid transparent;
	border-top-color: transparent;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
	border-radius: 8px;
}

#minicart-success-message {
	color: $color-success-msg;
	background-color: $bg-success-msg;
	border-color: $border-success-msg;
}

#minicart-error-message {
	color: $color-error-msg;
	background-color: $bg-error-msg;
	border-color: $border-error-msg;
}

.block-cart.skip-content {}

.qty-wrapper {
	display: flex;
	align-items: center;
	align-self: start;
	gap: 8px;

}

.cart-item-quantity,
input.qty {
	padding: 4px 8px;
	height: auto;
	text-align: center;
	max-width: 24px;
	aspect-ratio: 1;
	background: #fff;
}

.qty-sm {
	/* height: auto !important; */
	max-width: 26px !important;
	aspect-ratio: 1;
	font-size: 14px !important;
}

.quantity-button {
	visibility: hidden;
	opacity: 0;
}

.cart-item-quantity:focus+.quantity-button {
	visibility: visible;
	opacity: 1;
}

.minicart-wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;

	max-height: 100%;

	main {
		border-bottom: 1px solid #eee;
		overflow: auto;
		@include scrollbar-mini;

		.mini-products-list {
			overflow: hidden;
			gap: 0;
			&>.item-cart {
				margin-top: 8px;
				margin-right: 8px;
				box-shadow: unset;
			}
			.cart-price-unit {
				align-items: center;
				display: flex;
				gap: 4px;
			}
			.product-cart-actions {
				flex-direction: column;
			}
		}
		
		.product-cart-info {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-self: start;

			.product-name {
				line-height: 16px;
				@extend .t-truncate;
				@extend .t-truncate-2;
			}

		}
	}

	footer {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 4px;

		.subtotal {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.label,
			.price {
				font-size: $font-md;
			}

			.price {
				font-weight: bold;
			}
		}

		.minicart-actions {
			display: flex;
			flex-direction: column;
			margin-top: 8px;
			@media (min-width: 778px) {
				margin: 0;
				flex-direction: row;
				align-items: center;
			}
			gap: 8px;

			.button {
				width: auto;
				@media (min-width: 778px) {
					width: 100%;
				}
			}
		}
	}
}

// #shopping-cart-totals-table {
//   td {
//     border: none;
//     vertical-align: middle !important;
//     color: $color-body;
//     font-weight: normal !important;
//   }
//   td:first-child {
//     @extend h6;  
//     text-align: left;
//   }
//   .price {
//     @extend h6;
//     color: $color-body;
//   }
//   tfoot {
//     .price {
//       color: $color-brand;
//     }
//   }
//   @media only screen and (max-width:768px){
//     tbody, tfoot {
//       display: flex;
//       flex-direction: column;
//       tr {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//       }
//     }
//   }

// }
/*! purgecss start ignore */
.cart-totals-wrapper {
	background: #F5F3EC;
	border-radius: 8px;
	padding: 8px 20px;
	@media only screen and (min-width:1200px) {
		padding: 20px;
	}

	margin-bottom: 32px;

	.shipping {
		background: #fff;
		box-shadow: -2px 4px 12px rgba(85, 85, 85, 0.2);
		margin-bottom: 20px;
		border-radius: 6px;
		.shipping-form {
			padding: 12px 20px;
			#shipping-zip-form {
				ul {
					margin-bottom: 16px;
					& > li {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: space-between;
						gap: 8px;
						select {
							padding: 6px 12px;
							font-weight: 700;
						}
						label {
							font-weight: normal;
							font-size: 14px;
							@media (min-width:768px) {
								font-size: 18px;
							}
							&::after {
								content: '';
							}
						}
					}
				}
			}

			.sp-methods {
				display: flex;
				flex-direction: column;
				gap: 16px;
				dd {
					display: flex;
					ul {
						display: flex;
						flex-direction: column;
						gap: 16px;
					
						li {
							display: flex;
							align-items: center;
							gap: 8px;
							position: relative;
							label {
								font-size: 12px;
								@media (min-width: 768px) {
									font-size: 14px;
								}
								font-weight: normal;
								.price {
									font-weight: 700;
								}
							}
							.info-shipping {
								.popover {
									gap: 8px;
									padding: 10px;
									width: max-content;
									max-width: 100%;
									.popover__title {
										font-size: 18px;
									}
									.popover__content {
										font-size: 14px;
										line-height: normal;
									}
								}
							}
							/* .info-shipping {
								display: flex;
								cursor: help;
								p {
									display:  none;
									position: absolute;
									top:  24px;
									left: 0;
									border:  1px solid gainsboro;
									font-size: 12px;
									background: #eff5ea;
									border-radius: 3px;
									-webkit-border-radius: 3px;
									-moz-border-radius: 3px;
									-webkit-box-shadow: 0 0 6px 0 rgba(0,0,0,.15);
									-moz-box-shadow: 0 0 6px 0 rgba(0,0,0,.15);
									box-shadow: 0 0 6px 0 rgba(0,0,0,.15);
									padding: 4px 6px;
									z-index: 999999999;
								} 
								&:hover p,
								&:active p,
								&:focus p {
									display:  block;
								}
							} */
						}
					}
				}
			}
			
			
		}
		
	}

	.cart-totals {
		&:not(.sticky) {
			.method-checkout-cart-methods-fire {
				padding-bottom: 20px;
			}
		}
		
		.method-checkout-cart-methods-paypalpaylater_after {
			display: block;
			margin: 8px 4px;
		}
		
		&.sticky {


			.paypal_paylater_banner-cart {
				margin: 8px auto !important;
			}

			.paypal_paylater_banner-cart .message__container {
				text-align: center !important;
			}

			.checkout-types.bottom {
				display: flex;
				flex-direction: column-reverse;
			}

			/* colgroup,
			tbody {
				display: none;
			} */

			tfoot td {
				padding: 20px 4px;
			}

			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			/* max-width: 650px; */
			min-width: calc(360px - 15px);
			width: calc(100% - 16px);

			padding: 8px;
			border-top: 1px solid #eee;
			box-shadow: 0 4px 10px 0 #00000029;
			background: linear-gradient(0deg, #eee 0, rgb(255, 255, 255) 100%);

			z-index: 9999999999;

			@media (min-width: 778px) {
				padding: 0;
				position: relative;
				width: auto;
				max-width: none;
				box-shadow: none;
				border: none;
				background: transparent;
			}
		}
	}
}

/*! purgecss end ignore */

.btn-proceed-checkout,
.catalog-product-view .product-essential .btn-cart,
.checkout-button {
	color: #fff;
	position: relative;
	/* padding: 0px 40px; */
	/* line-height: 1; */
	/* border-radius: 8px; */
	//border: 1px solid $color-brand-2 !important;
	/* line-height: 50px; */
	font-weight: 700;
	&:not(.checkout-button) {
		height: 40px;
		font-size: 16px;
	}
	text-transform: uppercase;
	width: 100%;
	transition: all .25s cubic-bezier(.02, .01, .47, 1);
	/* box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px; */
	@media (hover: hover) and (pointer: fine) {
		&:hover {
			/* transform: translateY(-5px); */
			/* box-shadow: $bg-cta-gradient-shadow-hover; */
		}
	}

	i {
		margin-right: 10px;
	}
}

.trustinfo {
	max-width: 650px;
	padding: 0 1rem;

	@media only screen and (max-width:768px) {
		.row {
			display: block !important;

			.col-6 {
				width: 100%;

				&:last-child {
					margin-top: 10px;
				}
			}
		}
	}
}

.cart-totals-wrapper .trustinfo {
	
	span,
	strong,
	div {
		font-size: $font-sm;
	}
	
	img {
		width: auto;
		height: 24px;
	}
	
	max-width: 380px;
	
	.row {
		display: block !important;
		
		.col-6 {
			width: 100%;
			
			&:last-child {
				margin-top: 10px;
			}
		}
	}
}
/*! purgecss start ignore */
.header-minicart .cart-trust-info {
	svg {
		width: max-content;
		height: auto;
	}
}
.trust-info {
	hr {
		margin: 20px 0;
	}
	.trust-info__content {
		gap: 8px;
		width: 100%;
	}
}
/*! purgecss end ignore */

/*! purgecss start ignore */
.product-trustinfo {
	
}
/* .product-trustinfo {
	box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
	background: rgba(255,203,0,.03);
  	border: 1px solid #ffcb00;
	border-radius: 8px;
	padding: 14px;
} */
.product-trustinfo {	
	margin-top: 20px;
	line-height: normal;
	.trust-info {
		gap: 20px;
		> div {
			background: #F5F3EC;
			border-radius: 8px;
			padding: 20px;
		}
	}
	img {
		/*margin: 4px 0;
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		mix-blend-mode: darken;*/
	}
	.trust-info__payment {
		/* svg {
			height: 24px;
		} */
	}
}
.cart-trust-info {
	> div {
		.trust-info__shipping {
			strong {
				font-size: 14px;
				@media (min-width: 768px) {
					font-size: 18px;
				}
			}
			p {
				font-size: 12px;
				@media (min-width: 768px) {
					font-size: 14px;
				}
				line-height: normal;
			}
		}
	}
}
.paypal_paylater_banner-product {
	margin: 0 auto !important;
	text-align: center;
	/* box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
	background: #fbfbfb;
	border: 1px solid #f7f7f7;
	padding: 14px;
	border-radius: 8px; */
	span {
		display: flex;
	}
}
.shipping-countdown {
	/* box-shadow: 0 1px 1px 0 rgba(0,0,0,.02);
	background: #e9ffef;
  	border: 1px solid #8eff8e;
	padding: 14px;
	border-radius: 8px; */
	width: max-content;
}
/*! purgecss end ignore */

.bw-img {
	img {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		mix-blend-mode: darken;
	}
}

.rounded-mini-badges {
	img {
		margin: 4px;
		border: 1px solid #f6f9ff;
		background-color: #fff;
		border-radius: 2px;
		box-shadow: 0 0 1px 1px #f6f9ff;

	}
}