/*! purgecss start ignore */
.product-options dd:has(.swatchesContainerPadded) {
    gap: 0;
}

.swatchesContainerPadded {
    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        li {
            margin: 0;
            padding: 0;
            overflow: hidden;
            width: 64px;
            height: 64px;
            border: 1px solid #eee;
            border-radius: 8px;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                border-color: $color-brand;
            }
            &:has(.swatchSelected) {
                border-color: $color-brand;                
            }
            &:has(.disabledSwatch) {
                border-color: #fff;
                &:hover {
                    border-color: #fff;
                }
                cursor: not-allowed;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.5);
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}
/*! purgecss end ignore */