/*! purgecss start ignore */
.background-highlight {
    background-color: #f5e7e6;
}
.background-highlight2 {
    background-color: #F5F3EC;
}
.background-primary {
    background: $color-brand;
}
/*! purgecss end ignore */