/*! purgecss start ignore */
#wishlist-view-form {

    .cart-table {

        display: flex;
        flex-direction: column;
        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        gap: 16px;
    
        .item-cart {
            grid-template-areas:
                "image info info" 
                "image price price" !important;
                position: relative;
        }
        .wishlist-sku,
        .customer-wishlist-item-info textarea,
        .customer-wishlist-item-info .item-manage,
        .customer-wishlist-item-quantity {
            display: none;
        }
        .customer-wishlist-item-info .product-name {
            font-size: 14px;
        }
        .customer-wishlist-item-remove .btn-remove {
            display: grid;
            place-items: center;
            line-height: normal;
            background-color: #d92a64;
            color: #fff;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            text-align: center;
            content: 'X';
        }
        .customer-wishlist-item-image {
            grid-area: image;
            display: flex;
            min-width: 113px;
        }
        .customer-wishlist-item-info {
            grid-area: info;
            textarea {
                width: 50%;
            }
        }
        .customer-wishlist-item-quantity {
            grid-area: qty;
            > .cart-cell {
                display: flex;
                align-items: center;
                gap: 4px;
            }
            > ::before {
                content: "Qty";
            }
        }
        .customer-wishlist-item-price {
            grid-area: price;
        }
        .customer-wishlist-item-price .price-label {
            display: none;
        }
        .customer-wishlist-item-remove {
            grid-area: actions;
            position: absolute;
            top: -12px;
            right: -12px;
        }
        .price-box {
            display: grid;
            grid-template-areas: 
                'infolabel infolabel'
                'saveprice price'
                'oldprice oldprice';	
            align-items: center;
            gap: 8px 6px;
            max-width: fit-content;
            .old-price {
                grid-area: oldprice;
                order: 1;
                margin-right: 4px;			
                font-size: 14px;
                .price {
                    font-size: 14px;
                }
            }
            /* .minimal-price-link, */
            .save-price {
                grid-area: saveprice;
                order: 3;
                
                font-size: 18px;
                font-weight: 400;
                color: $color-brand;
                .price {
                    font-family: 'Georgia', 'Verdana';
                    font-size: 18px;
                }
            }
            .price,
            .special-price,
            .full-product-price {
                grid-area: price;
                order: 2;
                margin: 0;
                
                font-size: 20px;
                .price {
                    order: 0;
                    font-size: 20px;
                }
            }
            .minimal-price-link {
                display: none;
            }
            &>* {
                /* display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 8px; */
            }
    
            .special-price .price-label {
                display: none;
    
            }
            .price-as-configured {
                display: flex;
                flex-direction: column;
                gap: 4px;
                .price-label {
                    display: inherit;
                }
            }
            .price-info-label {
                grid-area: infolabel;
                line-height: normal;
                background: $color-brand;
                color: white;
                padding: 4px 16px;
                font-size: 12px;
                text-transform: uppercase;
                border-radius: 4px;
                width: fit-content;
            }
    
            //@extend .mb-1;
        }
    }

    .btn-add,
    .btn-update {
        display: none !important;
    }
}
/*! purgecss end ignore */