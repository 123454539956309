/*import vendors*/
@import url(vendors/normalize.css);

//@import url(vendors/bootstrap.min.css);
//@import 'base/bootstrap';
//@import "node_modules/bootstrap/scss/bootstrap";

//@import url(vendors/uicons-regular-straight.css);

/*import plugins*/
//@import url(plugins/magnific-popup.css);
/* @import url(plugins/animate.css); */
//@import url(plugins/select2.min.css);
/* @import url(plugins/slick.css); */
//@import url(plugins/perfect-scrollbar.css);

//@import url(plugins/animate.min.css);