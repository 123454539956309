/** Print **/
@media print {
    .back-top-home {
        display: none;
        opacity: 0;
        visibility: hidden;
    }
    .invoice-header, .invoice-top, .invoice-center, .invoice-bottom,.invoice-banner {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .invoice-top {
        .col-sm-9, .col-sm-3 {
             width: 50%;
        }
    }
    .col-sm-12 {
        width: 100%;
    }

    .col-sm-11 {
        width: 91.66666667%;
    }

    .col-sm-10 {
        width: 83.33333333%;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-8 {
        width: 66.66666667%;
    }

    .col-sm-7 {
        width: 58.33333333%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-5 {
        width: 41.66666667%;
    }

    .col-sm-4 {
        width: 33.33333333%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-2 {
        width: 16.66666667%;
    }

    .col-sm-1 {
        width: 8.33333333%;
    }

    .text-end {
        text-align: right !important;
    }

    .invoice-1 {
        padding: 0;
        background: #fff;
    }

    .invoice-1 .invoice-inner {
        background: #f8f8f8;
    }

    .invoice-1 .container {
        padding: 0px;
    }

    .invoice-1 .invoice-info {
        box-shadow: none;
        margin: 0px;
    }   
}