/*COMPONENTS -> FORM*/
/*! purgecss start ignore */
input:-moz-placeholder,
textarea:-moz-placeholder {
	opacity: 1;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	opacity: 1;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
	opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	opacity: 1;
}

select {
	-webkit-box-shadow: none;
	box-shadow: none;
	font-size: 14px;
	line-height: normal;
	color: $color-body;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	-ms-appearance: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding-right: 20px;
	border: 1px solid $border-color;
	padding-right: 32px !important;
	border-radius: 4px;

	background-color: #fff;
	background-repeat: no-repeat;
	background-size: 24px 24px;
	background-position-x: calc(100% - 8px);
	background-position-y: center;
	background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.9997 9.17C16.8123 8.98375 16.5589 8.87921 16.2947 8.87921C16.0305 8.87921 15.7771 8.98375 15.5897 9.17L11.9997 12.71L8.4597 9.17C8.27234 8.98375 8.01889 8.87921 7.7547 8.87921C7.49052 8.87921 7.23707 8.98375 7.0497 9.17C6.95598 9.26297 6.88158 9.37357 6.83081 9.49543C6.78004 9.61729 6.75391 9.74799 6.75391 9.88C6.75391 10.012 6.78004 10.1427 6.83081 10.2646C6.88158 10.3864 6.95598 10.497 7.0497 10.59L11.2897 14.83C11.3827 14.9237 11.4933 14.9981 11.6151 15.0489C11.737 15.0997 11.8677 15.1258 11.9997 15.1258C12.1317 15.1258 12.2624 15.0997 12.3843 15.0489C12.5061 14.9981 12.6167 14.9237 12.7097 14.83L16.9997 10.59C17.0934 10.497 17.1678 10.3864 17.2186 10.2646C17.2694 10.1427 17.2955 10.012 17.2955 9.88C17.2955 9.74799 17.2694 9.61729 17.2186 9.49543C17.1678 9.37357 17.0934 9.26297 16.9997 9.17Z" fill="black"/></svg>');
	&[disabled] {
		background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.9997 9.17C16.8123 8.98375 16.5589 8.87921 16.2947 8.87921C16.0305 8.87921 15.7771 8.98375 15.5897 9.17L11.9997 12.71L8.4597 9.17C8.27234 8.98375 8.01889 8.87921 7.7547 8.87921C7.49052 8.87921 7.23707 8.98375 7.0497 9.17C6.95598 9.26297 6.88158 9.37357 6.83081 9.49543C6.78004 9.61729 6.75391 9.74799 6.75391 9.88C6.75391 10.012 6.78004 10.1427 6.83081 10.2646C6.88158 10.3864 6.95598 10.497 7.0497 10.59L11.2897 14.83C11.3827 14.9237 11.4933 14.9981 11.6151 15.0489C11.737 15.0997 11.8677 15.1258 11.9997 15.1258C12.1317 15.1258 12.2624 15.0997 12.3843 15.0489C12.5061 14.9981 12.6167 14.9237 12.7097 14.83L16.9997 10.59C17.0934 10.497 17.1678 10.3864 17.2186 10.2646C17.2694 10.1427 17.2955 10.012 17.2955 9.88C17.2955 9.74799 17.2694 9.61729 17.2186 9.49543C17.1678 9.37357 17.0934 9.26297 16.9997 9.17Z" fill="%23888888"/></svg>');
		color: #888;
		border-color: #888;
		cursor: not-allowed;
	}
}
option {
	background: #fff;
	border: 0px solid #626262;
	padding-left: 10px;
	// font-size: $font-md;
}
textarea {
	border: 1px solid $border-color;
	border-radius: 4px;
	height: 45px;
	box-shadow: none;
	padding: 8px 16px;
	font-size: 14px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	&:focus {
		background: transparent;
		border: 1px solid $border-color-2;
	}
}
.wide textarea {
	width: 100%;
	min-height: 200px;
}
[type="checkbox"] {
	width: 16px;
	height: 16px;
	border: 2px solid $color-brand;
	background-color: #F2F3F7;
	border-radius: 4px;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	-ms-appearance: none;
	position: relative;
	display: inline-block;
	transition: all 0.2s ease-in-out;
	&:checked {
		background-color: $color-brand;
		border-color: $color-brand;
		&::before {
			content: '';
			position: absolute;
			top: -3px;
			left: 3px;
			width: 5px;
			height: 11px;
			border: solid white;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}
}
[type="radio"] {
	width: 16px;
	height: 16px;
	border: 2px solid #888;
	background-color: white;
	border-radius: 50%;
	cursor: pointer;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	-ms-appearance: none;
	position: relative;
	display: inline-block;
	transition: all 0.2s ease-in-out;
	&:checked {
		background-color: white;
		border-color: $color-brand;
		&::before {
			content: '';
			position: absolute;
			top: 2px;
			left: 2px;
			width: 8px;
			height: 8px;
			background-color: $color-brand;
			border-radius: 50%;
			transition: all 0.2s ease-in-out; 
		}
	}
}


.back-link a {
	text-decoration: underline;
}
.form-list input.input-text,
.form-list .input-box select {
	width: 100%;
}


.form-list {
	display: flex;
	flex-direction: column;
	gap: 8px;

	margin: 8px auto;
	& > li:not(.fields):not(.control):not(.cryozonic-stripe-elements) {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	
	.fields {
		display: flex;
		gap: 16px;
		.field {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;
		}
		.customer-name-middlename,
		.customer-name
		 {
			display: flex;
			flex: 1 0 100%;
			gap: 16px;
		}
	}

}

.form-instructions {
  //@extend .font-md;
}
.input-box {
	display: flex;
	flex-wrap: wrap;
	.form-instructions {
  	@extend .text-muted;
		@extend .font-xs;
	}
}
.fieldset {
	// margin: 32px auto;
}
legend,
.legend {
  font-size: 24px;
  font-weight: 500;
  margin: 8px auto;
  //@include title-decoration;
}

input.input-text,
.input-box select,
select.datetime-picker {
	border: 1px solid #888888;
	border-radius: 8px;
	/* box-shadow: none; */
	padding: 10px 16px;
	font-size: 14px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	/* background-color: #fff; */
	&:focus {
		/* background: transparent; */
		border: 1px solid $border-color-2;
	}
	&.square {
		border-radius: 0;
	}
	&.coupon {
		height: 47px;
	}
	&:user-invalid {
		border: 1px solid $color-danger;
		box-shadow: 0 0 0 .2rem rgba(161, 3, 3, 0.44);
	}
}
select.datetime-picker {
	/* float: left;
	width: min(100%, calc(33% - 12px)) !important;
	&:not(:last-child) {
		margin-right: 12px;
	} */
}
/*contact form*/
.contact-from-area {
	.contact-form-style {
		button {
			font-size: $font-lg;
			font-weight: 500;
			padding: 20px 40px;
			color: #ffffff;
			border: none;
			background-color: $color-heading;
			/* border-radius: 10px; */
			font-family: $font-heading;
			&:hover {
				background-color: $color-brand !important;
			}
		}
	}
}
.control,
.buttons-set {
	display: flex;
	gap: 8px;
}
.buttons-set {
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	p.required {
		flex-basis: 100%;
	}
}
.form-group {
	margin-bottom: 1rem;
	.input-box {
		margin-bottom: 1rem;
	}
	input {
		background: #fff;
		border: 1px solid $border-color;
		-webkit-box-shadow: none;
		box-shadow: none;
		padding-left: 20px;
		font-size: $font-md;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		&.input-text {
			height: 45px;
			width: 100%;
		}
		&:focus {
			background: transparent;
			border-color: $border-color-2;
		}
	}
}
label {
	margin-bottom: 5px;
}
.security-code {
	display: inline-block;
	/* border-radius: 10px; */
	height: 64px;
	line-height: 64px;
	padding: 0 40px;
	font-size: 24px;
	font-weight: bold;
	background: $border-color-2;
	b {
		font-size: 24px;
		font-weight: bold;
	}

}
.custome-radio .form-check-label,
.custome-checkbox .form-check-label {
	position: relative;
	cursor: pointer;
}
.custome-checkbox {
	.form-check-label {
		display: block;
		position: relative;
		cursor: pointer;
		color: #0f4c64;
		padding: 0;
		vertical-align: middle;
		&::before {
			content: "";
			border: 2px solid #ced4da;
			height: 17px;
			width: 17px;
			margin: 0px 8px 0 0;
			display: inline-block;
			vertical-align: middle;
			border-radius: 2px;
		}
		span {
			vertical-align: middle;
		}
	}
	input[type="checkbox"]:checked {
		& + .form-check-label {
			&::after {
				opacity: 1;
			}
		}
	}
	input[type="checkbox"] {
		& + .form-check-label {
			&::after {
				content: "";
				width: 11px;
				position: absolute;
				top: 50%;
				left: 3px;
				opacity: 0;
				height: 6px;
				border-left: 2px solid #fff;
				border-bottom: 2px solid #fff;
				-moz-transform: translateY(-65%) rotate(-45deg);
				-webkit-transform: translateY(-65%) rotate(-45deg);
				transform: translateY(-65%) rotate(-45deg);
			}
		}
	}
}
.custome-radio .form-check-input,
.custome-checkbox .form-check-input {
	display: none;
}
.login_footer {
	margin-bottom: 20px;
	margin-top: 5px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
	width: 100%;
}

.custome-checkbox {
	input[type="checkbox"]:checked {
		& + .form-check-label {
			&::before {
				background-color: $color-brand;
				border-color: $color-brand;
			}
			&::after {
				opacity: 1;
			}
		}
	}
}

.divider-text-center {
	text-align: center;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		right: 0;
		border-top: 1px solid #ddd;
		-moz-transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	span {
		background-color: #fff;
		padding: 0 15px;
		position: relative;
		text-transform: uppercase;
	}
}


/*comment*/
.comments-area {
	background: transparent;
	border-top: 1px solid $border-color;
	padding: 45px 0;
	margin-top: 50px;
	h5 {
		font-size: 16px;
		margin-bottom: 0px;
	}	
	.comment-list {
		padding-bottom: 48px;
		&:last-child {
			padding-bottom: 0px;
		}
		&.left-padding {
			padding-left: 25px;
		}
		.single-comment {
			margin: 0 0 15px 0;
			border: 1px solid #f2f2f2;
			border-radius: 15px;
			padding: 20px;
			transition: 0.2s;
			&:not(:last-child) {
				border-bottom: 1px solid $border-color;
			}
			img {
				min-width: 80px;
				max-width: 80px;
			}
			.reply {
				opacity: 0;
				transition: 0.2s;
			}
			&:hover {
				transform: translateY(-5px);
				transition: 0.2s;
				.reply {
					opacity: 1;
					transition: 0.2s;
				}
			}
		}
	}
	p {
		font-size: 16px !important;
	}
	.thumb {
		margin-right: 20px;
		img {
			width: 70px;
			border-radius: 50%;
		}
	}
	.date {
		font-size: 14px;
		color: #999999;
		margin-bottom: 0;
		margin-left: 20px;
	}
	.comment {
		margin-bottom: 10px;
		color: #777777;
		font-size: 15px;
	}
	.btn-reply {
		background-color: transparent;
		color: #888888;
		padding: 5px 18px;
		font-size: 14px;
		display: block;
		font-weight: 400;
	}
	&.style-2 {
		border: 0;
		margin-top: 0;
		padding: 25px 0;
	}
}
	.comments-area {
		h4 {
			margin-bottom: 35px;
			color: #2a2a2a;
			font-size: 18px;
		}
	}
	.comment-form {
		.email {
			padding-right: 0px;
		}
	}
	.form-control {
		border: 1px solid #f0e9ff;
		/* border-radius: 10px; */
		height: 48px;
		padding-left: 18px;
		font-size: $font-md;
		background: transparent;
	}

.comment-form {
	padding-top: 45px;
	margin-bottom: 20px;
	.form-group {
		margin-bottom: 20px;
	}
	textarea {
		// min-height: 200px;
		padding-top: 15px;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		&:focus {
			background: transparent;
			outline: none !important;
			-webkit-box-shadow: none;
			box-shadow: 0 0 0 .2rem rgba(1, 103, 156, 0.3);
			border-color: $border-color-2;
		}
	}
}
.form-control {
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	&:focus {
		outline: 0;
		box-shadow: 0 0 0 .2rem rgba(1, 103, 156, 0.3);
	}
	&::placeholder {
		font-weight: 300;
		color: #999999;
		color: #777777;
	}
}
.search-form form {
	position: relative;
	input {
		-webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
		-o-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
	button {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		right: 0;
		border: none;
		font-size: 20px;
		height: 100%;
		padding: 0 24px;
		background-color: transparent;
		color: $color-grey-2;
		&:hover {
			color: #fff;
		}
	}
}
input.qty-val {
	border: 0;
	border-radius: 0;
	height: unset;
	padding:0 !important;
	text-align: center;
}
/*! purgecss end ignore */