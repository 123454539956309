/*TYPOGRAPHY*/
body {
  color: $color-text;
  font-family: $font-text;
  font-optical-sizing: auto;
  font-size: 14px;
  /* line-height: 24px; */
  font-style: normal;
  /* font-weight: 400; */
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-heading,
.display-1,
.display-2,
.heading-sm-1
{
  font-optical-sizing: auto;
  line-height: 1.2;
}

.font-heading {
  font-family: 'Poppins', 'Adjusted Verdana Fallback';
}

.font-default {
  font-family: $font-text;
}

h1,
.h1 {
  font-family: 'Poppins', 'Adjusted Verdana Fallback';
  color: $color-heading;
  font-weight: 500;
  font-size: 26px;
  line-height: 28px;
  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 38px;
  }
}

h2,
.h2 {
  font-weight: 700;
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 26px;
  }
}

h3,
.h3 {
  font-size: 18px;
  @media (min-width: 768px) {
    font-size: 20px;
  }
}

h4,
.h4 {
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 18px;
  }
}

h5,
.h5 {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
}

h6,
.h6 {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
}
.display-1 {
  font-size: 96px;
  line-height: 1;
}
.display-2 {
  font-size: 72px;
  line-height: 1;
}
.heading-sm-1 {
  font-size: 14px;
}
p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 5px;
  color: $color-text;
}
.text-heading {
  color: $color-heading;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
p:last-child {
  margin-bottom: 0;
}
.font-weight-bold {
  font-weight: 700;
}
a,
button {
  text-decoration: none;
  cursor: pointer;
}
b {font-weight: 500;}
strong,
.fw-600 {
  font-weight: 600;
}
.fw-900 {
  font-weight: 800;
}
.fw-300 {
  font-weight: 300;
}

body:not(.firecheckout-index-index) {
  .section-title,
  .block-title,
  .page-title {
    display: flex;
    align-items: end;
    margin-bottom: 24px;
    position: relative;
    justify-content: space-between;
    .title {
      display: flex;
      flex-flow: row;
      align-items: flex-end;
    }
  }
}

.section-title,
.block-title,
.page-title {
  h3,
  > strong span {
    font-weight: 700;
    font-size: 32px;
    margin-right: 30px;
  }
  a.show-all {
    font-size: $font-md;
    color: $color-text;
    &:hover {
      color: $color-brand;
    }    
    i {
      font-size: 12px;
      margin-left: 5px;
    }
  }
  &.style-1,
  & > strong {
    position: relative;
    //@include title-decoration;
  }
  span {
    // color: $color-brand;
  }
}
  

.block-subtitle {
  font-size: 16px;
  // font-weight: bold;
  margin-bottom: 0.5em;
}  

h5.widget-title {
  font-size: 18px;
  font-weight: 600;
}
.title.style-3 {
  background-image: url(../imgs/theme/wave.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 25px;
}
.text-body {color: $color-text !important;}
.font-xxs{font-size:$font-xxs;}
.font-xs{font-size:$font-xs;}
.font-sm{font-size:$font-sm;}
.font-md{font-size:$font-md;}
.font-lg{font-size:$font-lg;}
.font-xl{font-size:$font-xl;}
.font-xxl{font-size:$font-xxl;}

.text-hot {color: $color-hot}
.text-new {color: $color-new}
.text-sale {color: $color-sale}
.text-best {color: $color-best}
/* 
.text-style-1 {
  position: relative;
  &::after {    
    content: "";
    background-color: #ffdabf;
    height: 20%;
    width: 110%;
    display: block;
    position: absolute;
    bottom: 20%;
    left: -5%;
    z-index: -1;
    opacity: 0.8;
    @include transform-duration-5s;
  }
  &:hover::after {
    height: 30%;
    @include transform-duration-5s;
  }
} */
.fw-700 {font-weight: 700;}

.t-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.t-truncate-1 {
  -webkit-line-clamp: 1;
}

.t-truncate-2 {
  -webkit-line-clamp: 2;
}
@media (min-width: 768px) {
  .t-truncate-md-0 {
      -webkit-line-clamp: 0 !important;
  }
  .t-truncate-md-1 {
    -webkit-line-clamp: 1 !important;
  }
  .t-truncate-md-2 {
      -webkit-line-clamp: 2 !important;
  }
  .t-truncate-md-3 {
    -webkit-line-clamp: 3 !important;
  }
}
.t-truncate-3 {
  -webkit-line-clamp: 3;
}